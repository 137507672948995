import { Component } from '@angular/core';
import {ModalService} from "../../services/modal.service";
import { DomSanitizer } from '@angular/platform-browser';
import {AuthService} from "../../services/auth.service";
import {TranslateService} from "../../services/translate.service";


@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent {
  askQuoteUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(this.translateService.translate("frame-url-ask-quote"));
  contactUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(this.translateService.translate("frame-url-contact"));
  subscriptionStringUrl = this.translateService.translate("frame-url-subscribe");
  subscriptionUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(this.translateService.translate("frame-url-subscribe"));

  constructor(public modalService: ModalService,
              public translateService: TranslateService,
              private domSanitizer: DomSanitizer) {
  }

  getFullSubscriptionUrl() {
    console.log('getFullSubscriptionUrl', this.modalService.options);
    let tempUrl = this.subscriptionStringUrl;
    if (this.modalService.options !== null)
    {
      tempUrl += '?';
      tempUrl += 'email=' + this.modalService.options.email;
      tempUrl += '&firstname=' + this.modalService.options.firstname;
      tempUrl += '&lastname=' + this.modalService.options.lastname;
      tempUrl += '&pack=' + this.modalService.options.pack;
    }
    console.log('getFullSubscriptionUrl', tempUrl);
    // + '?' + this.modalService.options.email?'email='+this.modalService.options.email + '&':'';
    return this.domSanitizer.bypassSecurityTrustResourceUrl(tempUrl);
  }



    getContactUrl() {
      return this.contactUrl;
    }

  getMeetingMinuteUrl() {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(this.modalService.options.minuteURL);
  }
}
