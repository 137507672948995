import { Injectable } from '@angular/core';
import {catchError, EMPTY, map, Subscription} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {Task} from "../models/task";
import {CommonService} from "./common.service";
import {EnvironmentService} from "./environment.service";

@Injectable({
  providedIn: 'root'
})
export class TaskService {
  userTasks: Task[] =[];
  project:string = "";
  // @ts-ignore
  getTaskSubscription:Subscription;

  constructor(private commonService: CommonService, private environmentService: EnvironmentService, private http: HttpClient) {
  }

  notionJsonToTask(json:any) {
    console.log("TaskService > notionJsonToTask", json);
    let tempTask: Task = {
      id : json.id,
      name : json["properties"]["Task name"]["title"][0]["plain_text"],
      status : this.getTaskStatus(json["properties"]["Status"]["status"]["name"]),
      projectId : json["properties"]["Project"]["relation"][0]?json["properties"]["Project"]["relation"][0]["id"]:'',
      creationDate : new Date()
    }
    return tempTask;
  }

  retrieveTasks(customerId:string) {
    this.getTaskSubscription = this.getTasksFromNotion(customerId).subscribe();
  }

  getTasksFromNotion(customerId:string = "test") {
    this.commonService.enableLoader();
    const headers = { 'content-type': 'application/json', 'Authorization': 'Bearer secret_7zOrfx8Ut1YwwCt4u06dkDYB9c2LqucGQSU2f5fZK4G', 'Notion-Version': '2022-06-28'};

    const requestJSON ={
      "filter": {
        "property": "Customer",
        "relation": {
          "contains": customerId
        }
      }};

    const body=JSON.stringify(requestJSON);
    return this.http.post(this.environmentService.getNotionUrl('getNotionTasks'),body, {headers})
      .pipe(map(res => {
          // @ts-ignore
          for (let task of res["results"]) {
            this.userTasks.push(this.notionJsonToTask(task));
          }

          this.commonService.disableLoader();
        }),
        catchError((err) => {
          console.log('Error retrieving tasks', err);
          this.commonService.disableLoader();
          return EMPTY;
        }));

  }
  getTaskById(taskId:string) {
    console.log("getTaskById", taskId);
    for (let task of this.userTasks) {
      if (task.id == taskId) return task;
    }
    let tempTask: Task = {
      id : '-1',
      name : 'empty',
      status : 'empty',
      creationDate : new Date()
    }
    return tempTask;
  }

  getTaskStatus(taskStatus: string) {
    let tempTaskStatus:string;

    switch(taskStatus) {
      case 'Not started' :
                tempTaskStatus = 'todo';
                break;
      case 'In progress':
                tempTaskStatus = 'ongoing';
                break;
      case 'Canceled':
        tempTaskStatus = 'archived';
        break;
      default : return taskStatus.toLowerCase();
    }
    return tempTaskStatus.toLowerCase();
  }

  emptyTasks() {
    this.userTasks = [];
    this.getTaskSubscription.unsubscribe();
  }
}
