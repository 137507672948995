import {Injectable} from "@angular/core";
import {AuthService} from "./auth.service";
import {TranslateService} from "./translate.service";

@Injectable({
  providedIn: 'root'
})
export class PasswordService {

  constructor(public translateService: TranslateService, private authService:AuthService) {
  }
  needSpecial = true;
  needUpper = true;
  needNumber = true;

  minPasswordLength = 8;

  areSamePassword(pwd1: string, pwd2:string) {
    return pwd1 === pwd2;
  }

  containsNumber(str:string) {
    return /\d/.test(str);
  }
  containsUppercase(str:string) {
    return /[A-Z]/.test(str);
  }
  containsSpecial(str:string) {
    return /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(str);
  }
  checkPasswords (pwd1: string, pwd2:string) {

    if (this.needNumber) {
      if (!this.containsNumber(pwd1)) return false;
    }

    if (this.needUpper) {
      if (!this.containsUppercase(pwd1)) return false;
    }

    if (this.needSpecial) {
      if (!this.containsSpecial(pwd1)) return false;
    }

    if(pwd1.length < this.minPasswordLength) return false;

    return this.areSamePassword(pwd1, pwd2);

  }
  displayCheckPasswordsResults (pwd1: string, pwd2:string) {

    let checkResults: string;

    checkResults = "<ul>";

    if (this.needNumber) {
      if (!this.containsNumber(pwd1)) {
        checkResults += "<li class='error'>"+this.translateService.translate("contains-a-number")+"</li>";
      }
      else {
        checkResults += "<li class='success'>"+this.translateService.translate("contains-a-number")+"</li>";
      }
    }

    if (this.needUpper) {
      if (!this.containsUppercase(pwd1)) {
        checkResults += "<li class='error'>"+this.translateService.translate("contains-at-least-1-uppercase-letter")+"</li>";
      }
      else {
        checkResults += "<li class='success'>"+this.translateService.translate("contains-at-least-1-uppercase-letter")+"</li>";
      }
    }

    if (this.needSpecial) {
      if (!this.containsSpecial(pwd1)) {
        checkResults += "<li class='error'>"+this.translateService.translate("contains-a-special-character")+"</li>";
      }
      else {
        checkResults += "<li class='success'>"+this.translateService.translate("contains-a-special-character")+"</li>";
      }
    }

    if(pwd1.length < this.minPasswordLength) {
      checkResults += "<li class='error'>"+this.translateService.translate("password-must-be-at-least").replace("%s%", this.minPasswordLength)+"</li>";
    }
    else {
      checkResults += "<li class='success'>"+this.translateService.translate("password-must-be-at-least").replace("%s%", this.minPasswordLength)+"</li>";
    }
    if(!this.areSamePassword(pwd1, pwd2)) {
      checkResults += "<li class='error'>"+this.translateService.translate("the-2-passwords-are-different")+"</li>";
    }
    checkResults += "</ul>";
    return checkResults;
  }

  changePassword(newPwd:string) {
    this.authService.updatePassword(newPwd);
  }


}
