import { Component } from '@angular/core';
import {AuthService} from "../services/auth.service";
import {ModalService} from "../services/modal.service";
import {CommonService} from "../services/common.service";
import {AnalyticsService} from "../services";
import {TranslateService} from "../services/translate.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  /*let mainmenu = document.getElementById("menu");
  let hamburger = document.getElementById("hamburger");
  let closeMenu = document.getElementById("close-menu");
*/
  constructor(private analyticsService: AnalyticsService,
              private authService: AuthService,
              private router: Router,
              public commonService: CommonService,
              public modalService: ModalService,
              public translateService: TranslateService) {
  }
  menuOpen:boolean = false;
  scroll(el: string) {
    this.analyticsService.trackEvent('menu link', el, 'TOP_MENU_CLICKED');
    let tempDocument = document;
    if (this.router.url == "/public") {
      // @ts-ignore
      document.getElementById(el).scrollIntoView();
    }
    else {this.router.navigateByUrl('/public').then(() => {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    })
    }
  }
  isUserAuth(){return this.authService.userIsAuthenticated;}

  logout() {
    this.menuOpen = false;
    this.authService.logout();
  }
  toggleMenu() : void{
    this.menuOpen = !this.menuOpen;
  }

}
