import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {catchError, EMPTY, map} from "rxjs";
import {New} from "../models/new";
import {EnvironmentService} from "./environment.service";

@Injectable({
  providedIn: 'root'
})
export class NewsService {
  news: New[] =[];

  constructor(private environmentService: EnvironmentService, private http: HttpClient) {
    this.getNews();
  }

  notionJsonToNew(json:any) {
    let tempNew: New = {
      id : json.id,
      label :json["properties"]["label"]["title"][0]["plain_text"],
      url : json["properties"]["url"]["url"] ? json["properties"]["url"]["url"] : '',
      creationDate : json["properties"]["creationDate"]["date"]["start"]
    }
    return tempNew;
  }
  getNews() :void {
    this.getNewsFromNotion().subscribe();
  }
  getNewsFromNotion() {
    const headers = { 'content-type': 'application/json', 'Authorization': 'Bearer secret_7zOrfx8Ut1YwwCt4u06dkDYB9c2LqucGQSU2f5fZK4G', 'Notion-Version': '2022-06-28'};

    const requestJSON = {
      "filter": {
        "and": [
          {
            "property": "published",
            "checkbox": {
              "equals": true
            }
          },
          {
            "property": "published",
            "checkbox": {
              "equals": true
            }
          }
        ]
      }
    };
    const body=JSON.stringify(requestJSON);

    return this.http.post(this.environmentService.getApiRequest('notionproxy'),body, {headers})
      .pipe(map(res => {
          // @ts-ignore
          for (let newItem of res["results"]) {
            this.news.push(this.notionJsonToNew(newItem));
          }
        }),
        catchError((err) => {
          console.log('Error retrieving news', err);
          return EMPTY;
        }));
  }
}
