<nav class="sidebar" [class.menu-open]="commonService.isLeftMenuVisible" [class.menu-closed]="!commonService.isLeftMenuVisible">
  <a (click)="closeMenu()" title="Close Sidemenu"
     class="close-btn"><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><path fill="#666666" d="M2 6v20a2 2 0 0 0 2 2h24a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2m20 0h6v20h-6zM4 6h16v9H9.83l3.58-3.59L12 10l-6 6l6 6l1.41-1.41L9.83 17H20v9H4z"/></svg></a>
  <!--<a class="btn-main" (click)="newMessage()">New Message</a>-->
  <a class="dashboard-link" [class.is-active]="commonService.getCurrentPageTitle() == 'Dashboard'" [routerLink]="['/secured']" i18n>Dashboard</a>
  <div class="ongoing-projects">
    <ng-container *ngFor="let project of projectList">
      <a [class.is-active]="commonService.getCurrentPageTitle() == project.name" [routerLink]="['/secured/project']" [queryParams]="{id: project.id}">{{project.name}}
        <progress  [value]="getProgress(project)" max="100"> {{getProgress(project)}}% </progress></a>
    </ng-container>
    <a *ngIf="timeSheetService.countTimesheetHours('To pay')" class="ongoing-off-project" routerLink="/secured/project/off"><span class="task-hours">{{timeSheetService.countTimesheetHours('To pay')}}h </span><span i18n> out of scope</span> ({{timeSheetService.calculateTimesheetToPay()}})</a>
    <!--<a class="archives" href="#">voir les projets archivés</a>-->
  </div>
  <a class="picture-link" [routerLink]="['/secured/picture-generator']">Picture Generator</a>

  <!--
  <div class="digital-maturity">
    <h2>Maturité digitale de votre entreprise</h2>
    <div class="indicator">
      <span class="digital-label">Stratégie globale</span>
      <span class="score">2/5</span>
      <progress value="40" max="100"> 40% </progress>
    </div>
    <div class="indicator">
      <span class="digital-label">Culture du numérique</span>
      <span class="score">2/5</span>
      <progress value="40" max="100"> 40% </progress>
    </div>
    <div class="indicator">
      <span class="digital-label">Sécurité</span>
      <span class="score">2/5</span>
      <progress value="40" max="100"> 40% </progress>
    </div>
    <div class="indicator">
      <span class="digital-label">Innovation</span>
      <span class="score">2/5</span>
      <progress value="40" max="100"> 40% </progress>
    </div>
  </div>
  -->
</nav>

<!-- Overlay effect when opening the side navigation on small screens -->
<div class="nav-overlay" [class.menu-open]="commonService.isLeftMenuVisible" [class.menu-closed]="!commonService.isLeftMenuVisible" (click)="closeMenu()" title="Close Sidemenu"></div>



