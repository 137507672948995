import {Component} from '@angular/core';
import {NgIf} from "@angular/common";
import {TranslateService} from "../services/translate.service";

@Component({
  selector: 'app-privacy-policy',
  standalone: true,
    imports: [
        NgIf
    ],
  templateUrl: './privacy-policy.component.html',
  styleUrl: './privacy-policy.component.scss'
})
export class PrivacyPolicyComponent {
  constructor(public translateService: TranslateService) {
  }
}
