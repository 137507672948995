<div class="container" *ngIf="translateService.currentLanguage!=='fr-FR'">
  <h1>Privacy Policy</h1>
  <p>This Privacy Policy describes how Tranquilys ("we," "us," or "our") collects, uses, and shares personal information when you use our website, tranquilys.com (the "Site").</p>

  <h2>1. Collection of Personal Information</h2>

  <p>When you visit the Site, we collect certain information about your device, including information about your web browser, IP address, time zone, and some of the cookies that are installed on your device. Additionally, as you browse the Site, we collect information about the individual web pages or products that you view, what websites or search terms referred you to the Site, and information about how you interact with the Site. We refer to this automatically-collected information as "Device Information."</p>
  <br/>
  <p>We collect Device Information using the following technologies:</p>
  <br/>
  <p>"Cookies" are data files that are placed on your device or computer and often include an anonymous unique identifier. For more information about cookies, and how to disable cookies, visit www.allaboutcookies.org.</p>
  <br/>
  <p>"Log files" track actions occurring on the Site, and collect data including your IP address, browser type, Internet service provider, referring/exit pages, and date/time stamps.</p>
  <br/>
  <p>"Web beacons," "tags," and "pixels" are electronic files used to record information about how you browse the Site.</p>

  <h2>2. Use of Personal Information</h2>

  <p>We use the Device Information that we collect to help us screen for potential risk and fraud (in particular, your IP address), and more generally to improve and optimize our Site (for example, by generating analytics about how our customers browse and interact with the Site, and to assess the success of our marketing and advertising campaigns).</p>

  <h2>3. Sharing of Personal Information</h2>

  <p>We share your Personal Information with third parties to help us use your Personal Information, as described above. For example, we use Google Analytics to help us understand how our customers use the Site--you can read more about how Google uses your Personal Information here: https://www.google.com/intl/en/policies/privacy/. You can also opt-out of Google Analytics here: https://tools.google.com/dlpage/gaoptout.</p>

  <h2>4. Your Rights</h2>

  <p>Additionally, if you are a European resident, please note that your information will be transferred outside of Europe, including to Canada and the United States.</p>

  <h2>5. Data Retention</h2>

  <p>When you place an order through the Site, we will maintain your Order Information for our records unless and until you ask us to delete this information.</p>

  <h2>6. Changes</h2>

  <p>We may update this privacy policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal, or regulatory reasons.</p>

  <h2>7. Contact Us</h2>

  <p>For more information about our privacy practices, if you have questions, or if you would like to make a complaint, please contact us by e-mail at dpo&#64;tranquilys.com or by mail using the details provided below:</p>
  <br/>
  <p>Tranquilys - DPO, MORAND Stephan<br/>
    229 rue Saint-Honoré 75001 PARIS</p>


</div>
<div class="container" *ngIf="translateService.currentLanguage=='fr-FR'">
  <h1>Politique de confidentialité</h1>
  <p>Cette Politique de confidentialité décrit comment Tranquilys ("nous," "nous," ou "notre") collecte, utilise et partage les informations personnelles lorsque vous utilisez notre site Web, tranquilys.com (le "Site").</p>

  <h2>1. Collecte d'informations personnelles</h2>

  <p>Lorsque vous visitez le Site, nous collectons certaines informations sur votre appareil, y compris des informations sur votre navigateur Web, votre adresse IP, votre fuseau horaire et certains des cookies qui sont installés sur votre appareil. De plus, lorsque vous naviguez sur le Site, nous recueillons des informations sur les pages Web ou les produits individuels que vous consultez, les sites Web ou les termes de recherche qui vous ont référé au Site, ainsi que des informations sur la manière dont vous interagissez avec le Site. Nous désignons ces informations collectées automatiquement sous le nom d' "Informations sur l'appareil".</p>
  <br/>
  <p>Nous collectons des Informations sur l'appareil à l'aide des technologies suivantes:</p>
  <br/>
  <p>Les "Cookies" sont des fichiers de données qui sont placés sur votre appareil ou votre ordinateur et incluent souvent un identifiant unique anonyme. Pour plus d'informations sur les cookies et sur la manière de les désactiver, visitez www.allaboutcookies.org.</p>
  <br/>
  <p>Les "Fichiers journaux" suivent les actions qui se produisent sur le Site et collectent des données telles que votre adresse IP, le type de navigateur, le fournisseur de services Internet, les pages de référence/sortie et les horodatages.</p>
  <br/>
  <p>Les "Balises web," "tags," et "pixels" sont des fichiers électroniques utilisés pour enregistrer des informations sur votre navigation sur le Site.</p>

  <h2>2. Utilisation des informations personnelles</h2>

  <p>Nous utilisons les Informations sur l'appareil que nous collectons pour nous aider à identifier les risques potentiels et la fraude (en particulier votre adresse IP), et plus généralement pour améliorer et optimiser notre Site (par exemple, en générant des analyses sur la manière dont nos clients naviguent et interagissent avec le Site, et en évaluant le succès de nos campagnes marketing et publicitaires).</p>

  <h2>3. Partage des informations personnelles</h2>

  <p>Nous partageons vos Informations personnelles avec des tiers pour nous aider à utiliser vos Informations personnelles, tel que décrit ci-dessus. Par exemple, nous utilisons Google Analytics pour nous aider à comprendre comment nos clients utilisent le Site - vous pouvez en savoir plus sur la manière dont Google utilise vos Informations personnelles ici: https://www.google.com/intl/fr/policies/privacy/. Vous pouvez également désactiver Google Analytics ici: https://tools.google.com/dlpage/gaoptout.</p>

  <h2>4. Vos droits</h2>

  <p>De plus, si vous êtes un résident européen, veuillez noter que vos informations seront transférées en dehors de l'Europe, y compris au Canada et aux États-Unis.</p>

  <h2>5. Conservation des données</h2>

  <p>Lorsque vous passez une commande sur le Site, nous conservons vos Informations de commande pour nos dossiers, sauf si et jusqu'à ce que vous nous demandiez de supprimer ces informations.</p>

  <h2>6. Modifications</h2>

  <p>Nous pouvons mettre à jour cette politique de confidentialité de temps à autre afin de refléter, par exemple, des changements dans nos pratiques ou pour d'autres raisons opérationnelles, légales ou réglementaires.</p>

  <h2>7. Contactez-nous</h2>

  <p>Pour plus d'informations sur nos pratiques en matière de confidentialité, si vous avez des questions ou si vous souhaitez déposer une plainte, veuillez nous contacter par e-mail à l'adresse dpo&#64;tranquilys.com ou par courrier en utilisant les coordonnées fournies ci-dessous:</p>
  <br/>
  <p>Tranquilys - DPO, MORAND Stephan<br/>
    229 rue Saint-Honoré 75001 PARIS</p>

</div>





