<div *ngIf="project.id != '-1'" class="project-box">
  <div class="project-box-container">
    <h1>{{project.name}}</h1>
  </div>
  <progress *ngIf="project.project_type=='Project'" class="project-progress" [value]="getProgress()" max="100"> {{getProgress()}}% </progress>
</div>

<div *ngIf="project.name == 'dummy'" class="project-box">
  <div class="project-box-container">
    <h1>{{ translateService.translate("no-project-yet") }}</h1>
  </div>
  <span>{{ translateService.translate("no-project-yet-detail") }}</span>
</div>

<div *ngIf="project.name == '-1'" class="project-box">
  <div class="project-box-container">
    <h1>{{translateService.translate("new-project-question")}}</h1>
  </div>
  <button class="ask-quote" (click)="modalService.showModal('contact')">Contact</button>
</div>


