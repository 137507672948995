import { Component } from '@angular/core';

@Component({
  selector: 'app-get-your-tranquilys',
  standalone: true,
  imports: [],
  templateUrl: './get-your-tranquilys.component.html',
  styleUrl: './get-your-tranquilys.component.scss'
})
export class GetYourTranquilysComponent {

}
