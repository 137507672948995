import { Component } from '@angular/core';
import {AuthService} from "../../services/auth.service";
import {DatePipe} from "@angular/common";
import {TimesheetService} from "../../services/timesheet.service";

@Component({
  selector: 'app-subscription-summary',
  standalone: true,
  imports: [
    DatePipe
  ],
  templateUrl: './subscription-summary.component.html',
  styleUrl: './subscription-summary.component.scss'
})
export class SubscriptionSummaryComponent {
  constructor(public authService:AuthService,
              public timesheetService:TimesheetService) {
  }
  getCurrentMonthCounter() {
    return this.timesheetService.countTimesheetHours('Packaged', this.authService.getPreviousAnniversaryDate(), new Date());
  }

  getLastMonthCounter() {
    let lastDate = new Date(this.authService.getPreviousAnniversaryDate().getMonth() + '-' + this.authService.getPreviousAnniversaryDate().getDate() + '-' + (this.authService.getPreviousAnniversaryDate().getMonth() === 0 ? (this.authService.getPreviousAnniversaryDate().getFullYear() - 1): this.authService.getPreviousAnniversaryDate().getFullYear()));
    console.log("getLastMonthCounter", lastDate);
    return this.timesheetService.countTimesheetHours('Packaged', lastDate, this.authService.getPreviousAnniversaryDate());
  }
}
