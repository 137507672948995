import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {catchError, EMPTY, map, Subscription} from "rxjs";
import {Audit, AuditCategory, AuditLine} from "../models/audit";
import {EnvironmentService} from "./environment.service";

@Injectable({
  providedIn: 'root'
})
export class AuditService {

  userAudits: Audit[] =[];
  // @ts-ignore
  getAuditSubscription:Subscription;
  // @ts-ignore
  getAuditCategorySubscription:Subscription;
  // @ts-ignore
  getAuditLineSubscription:Subscription;

  constructor(private http: HttpClient,
              private environmentService: EnvironmentService) {}

  notionJsonToAudit(json:any) {
    console.log("notionJSON", json);
    let tempAudit: Audit = {
      id : json.id,
      company : json["properties"]["CustomerName"]["formula"]["string"],
      name : json["properties"]["Name"]["title"][0]["plain_text"],
      conclusion : json["properties"]["Conclusion"]["rich_text"][0]["plain_text"],
      description : json["properties"]["Description"]["rich_text"][0]["plain_text"],
      categories : [],
      audit_lines : [],
      preview : json["properties"]["preview"]["checkbox"]
    }
    return tempAudit;
  }
  notionJsonToAuditCategory(json:any) {

    let tempAuditCategory: AuditCategory = {
      id : json.id,
      name : json["properties"]["Name"]["title"][0]["plain_text"],
      description: json["properties"]["Description"]["rich_text"][0]["plain_text"],
      imageURL : json["properties"]["Illustration"] && (json["properties"]["Illustration"]["files"][0] != undefined )?json["properties"]["Illustration"]["files"][0]["file"]["url"]: ''
    }
    return tempAuditCategory;
  }
  notionJsonToAuditLine(json:any, auditId:string) {
    let tempAuditLine: AuditLine = {
      id: json.id,
      audit_id : auditId,
      category_id : json["properties"]["Audit categories"]["relation"][0]["id"],
      priority : json["properties"]["Priority"]["select"]["name"],
      name : json["properties"]["Name"]["title"][0]["plain_text"],
      description: json["properties"]["Description"]["rich_text"][0]["plain_text"],
      score:json["properties"]["Score"]["number"],
      analysis : json["properties"]["Analysis"]["rich_text"][0]["plain_text"],
      todo : json["properties"]["To do"]["rich_text"][0]["plain_text"],
      screenshotUrl : json["properties"]["Screenshot"] && (json["properties"]["Screenshot"]["files"][0] != undefined )?json["properties"]["Screenshot"]["files"][0]["file"]["url"]: ''
    }
    return tempAuditLine;
  }

  retrieveAudits(customerId:string) {
    this.getAuditSubscription = this.getAuditFromNotion(customerId).subscribe();
  }
  retrieveAuditCategories(auditId:string) {
    this.getAuditCategorySubscription = this.getAuditCategoriesFromNotion(auditId).subscribe();
  }
  retrieveAuditLines(auditId:string) {
    this.getAuditLineSubscription = this.getAuditLinesFromNotion(auditId).subscribe();
  }

  getAuditFromNotion(customerId:string = "test") {
    const headers = { 'content-type': 'application/json', 'Authorization': 'Bearer secret_7zOrfx8Ut1YwwCt4u06dkDYB9c2LqucGQSU2f5fZK4G', 'Notion-Version': '2022-06-28'};
    const requestJSON = {
      "filter": {
        "property": "Customer",
        "relation": {
          "contains": customerId
        }
      }};

    const body=JSON.stringify(requestJSON);

    return this.http.post(this.environmentService.getNotionUrl('getNotionAudits'),body, {headers})
      .pipe(map(res => {
          // @ts-ignore
          for (let audit of res["results"]) {
            this.userAudits.push(this.notionJsonToAudit(audit));
            this.retrieveAuditCategories(audit.id);
            this.retrieveAuditLines(audit.id);
          }
        }),
        catchError((err) => {
          console.log('Error retrieving Audit', err);
          return EMPTY;
        }));
  }

  getAuditCategoriesFromNotion(auditId:string) {
    const headers = { 'content-type': 'application/json', 'Authorization': 'Bearer secret_7zOrfx8Ut1YwwCt4u06dkDYB9c2LqucGQSU2f5fZK4G', 'Notion-Version': '2022-06-28'};
    const requestJSON = {
      "filter": {
        "property": "Audits",
        "relation": {
          "contains": auditId
        }
      }};

    const body=JSON.stringify(requestJSON);

    return this.http.post(this.environmentService.getNotionUrl('getNotionAuditCategories'),body, {headers})
      .pipe(map(res => {
          // @ts-ignore
          for (let auditCategory of res["results"]) {
            this.userAudits[this.getAuditIndex(auditId)].categories.push(this.notionJsonToAuditCategory(auditCategory));
          }
        }),
        catchError((err) => {
          console.log('Error retrieving Audit Category', err);
          return EMPTY;
        }));
  }

  getAuditLinesFromNotion(auditId:string) {
    const headers = { 'content-type': 'application/json', 'Authorization': 'Bearer secret_7zOrfx8Ut1YwwCt4u06dkDYB9c2LqucGQSU2f5fZK4G', 'Notion-Version': '2022-06-28'};
    const requestJSON = {
      "filter": {
        "property": "Audits",
        "relation": {
          "contains": auditId
        }
      }};

    const body=JSON.stringify(requestJSON);

    return this.http.post(this.environmentService.getNotionUrl('getNotionAuditLines'),body, {headers})
      .pipe(map(res => {
          // @ts-ignore
          for (let auditLine of res["results"]) {
            this.userAudits[this.getAuditIndex(auditId)].audit_lines.push(this.notionJsonToAuditLine(auditLine, auditId));
          }
        }),
        catchError((err) => {
          console.log('Error retrieving audit lines', err);
          return EMPTY;
        }));
  }
  getAuditIndex(auditId:string) {
    let i = 0;
    for (let audit of this.userAudits) {
      if (audit.id === auditId) return i;
      i++;
    }
    return -1;
  }

  getAuditById(auditId:string) {
    for (let audit of this.userAudits) {
      if (audit.id == auditId) return audit;
    }
    let tempAudit: Audit = {
      id : '-1',
      company : '',
      name : 'empty',
      conclusion: '',
      description: '',
      categories : [],
      audit_lines : []
    }
    return tempAudit;
  }

  emptyAudits() {
    this.userAudits = [];
    this.getAuditSubscription.unsubscribe();
  }
}
