<div id="services" class="container">
  <div class="service-box" *ngFor="let service of offeringService.offerings" [id]="service.id">
    <div class="service-content">
      <div class="text-content">
        <h2>{{translateService.getShortLanguage()== "fr" ? service.title_fr : service.title_en}}</h2>
        <p>{{translateService.getShortLanguage()== "fr" ? service.description_fr : service.description_en}}</p>
        <ul class="offer-list">
          <li>{{translateService.getShortLanguage()== "fr" ? service.offers[0].title_fr : service.offers[0].title_en}}</li>
          <li>{{translateService.getShortLanguage()== "fr" ? service.offers[1].title_fr : service.offers[1].title_en}}</li>
          <li>{{translateService.getShortLanguage()== "fr" ? service.offers[2].title_fr : service.offers[2].title_en}}</li>
        </ul>
      </div>
      <img [class.img-link] = "service.id == 'digital-audit'||service.id == 'webdev'" [src]="translateService.getShortLanguage() == 'fr' ? service.img_fr : service.img_en" alt="" (click)="modalService.showModal(service.id)"/>
    </div>

  </div>
</div>
