<div id="about" class="container">
  <span class="heros-text">
    <span class="size-4">Digital</span><br>
    <span class="size-1">in</span>
    <span class="size-3">Serenity</span>
  </span>
  <h1 class="pill" data-aos="fade-up" data-aos-duration="800">{{translateService.translate("Surf the digital wave in serenity")}}</h1>
  <p data-aos="fade-up" data-aos-duration="800" [innerHTML]="translateService.translate('home-welcome-description')"></p>
  <!--<button data-aos="fade-up" data-aos-duration="800" data-aos-offset="200">Find out more</button>-->
</div>
