import {Component, Input} from '@angular/core';
import {Resource} from "../../../models/resource";
import {Tag} from "../../../models/tag";
import {Task} from "../../../models/task";

@Component({
  selector: 'app-resources-box',
  templateUrl: './resources-box.component.html',
  styleUrls: ['./resources-box.component.scss']
})
export class ResourcesBoxComponent {

  @Input() resources: Resource[] =[];

  page: number = 1;
  resourcesPerPage = 10;

  isFiltered: boolean = false;
  filterTags: Tag[] = [];
  filteredResources: Resource [] = this.resources;
  isTagInList(tag: Tag, tagList: Tag[]) {
    for (let tagItem of tagList) {
      if (tagItem.id === tag.id ) return true;
    }
    return false;
  }

  areTagsInList(tags: Tag[], tagList: Tag[]) {
    if (!this.isFiltered) return true;
    for (let tagItem of tags) {
      if (!this.isTagInList(tagItem, tagList)) return false;
    }
    return true;
  }

  matchFilter(resource: Resource) {
    return (resource.tags?this.areTagsInList(this.filterTags, resource.tags):false);
  }
  emptyFilter() {
    this.isFiltered = false;
    this.filterTags = [];
  }
  removeFilter(tag: Tag) {
    let i = 0;
    for (let tag of this.filterTags) {
      if (tag.id == this.filterTags[i].id) break;
      i ++;
    }
    this.filterTags.splice(i, 1);
    if (this.filterTags.length == 0) this.emptyFilter();
    this.updateFilteredResources ();
  }

  addFilter(tag: Tag) {
    if (!this.isTagInList(tag, this.filterTags)) {
      this.filterTags.push(tag);
      this.isFiltered = true;
    }
    this.updateFilteredResources ();
  }

  getFilteredResources () {
    this.updateFilteredResources();
    return this.filteredResources;
  }
  updateFilteredResources () {
    let tempResources: Resource[] = [];
    for (let Resource of this.resources) {
      if (this.matchFilter(Resource)) tempResources.push(Resource);
    }

    this.filteredResources = tempResources;
  }
  editResource(resource: Resource) {
    //TODO
    console.log("edit resource");
  }
}
