import { NgModule } from '@angular/core';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PublicComponent } from './public/public.component';
import { SecuredComponent } from './secured/secured.component';
import { LoginComponent } from './login/login.component';
import { MenuComponent } from './menu/menu.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { NewsBoxComponent } from './news-box/news-box.component';
import {ReactiveFormsModule} from "@angular/forms";
import {HttpClientModule} from "@angular/common/http";
import {ToastrModule} from "ngx-toastr";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import { CurrentProjectLinksComponent } from './secured/current-project-links/current-project-links.component';
import { NavigationComponent } from './secured/navigation/navigation.component';
import { ModalComponent } from './shared/modal/modal.component';
import { ProjectComponent } from './secured/project/project.component';
import { ResourcesBoxComponent } from './secured/project/resources-box/resources-box.component';
import {NgxPaginationModule} from "ngx-pagination";
import { TasksBoxComponent } from './secured/project/tasks-box/tasks-box.component';
import { OffProjectComponent } from './secured/project/off-project/off-project.component';
import { DashboardComponent } from './secured/dashboard/dashboard.component';
import { ProjectBoxComponent } from './secured/dashboard/project-box/project-box.component';
import { OffProjectBoxComponent } from './secured/dashboard/off-project-box/off-project-box.component';
import { DigitalMaturityBoxComponent } from './secured/dashboard/digital-maturity-box/digital-maturity-box.component';
import { AddResourceComponent } from './secured/project/resources-box/add-resource/add-resource.component';
import { MeetingListComponent } from './secured/dashboard/meeting-list/meeting-list.component';
import {PresentationComponent} from "./public/presentation/presentation.component";
import {ServiceListComponent} from "./public/service-list/service-list.component";
import {ContactComponent} from "./public/contact/contact.component";
import {OffersComponent} from "./public/offers/offers.component";
import {TimeBoxComponent} from "./secured/dashboard/time-box/time-box.component";
import {SubscriptionBoxComponent} from "./public/offers/subscription-box/subscription-box.component";
import {ProfileBoxComponent} from "./secured/dashboard/profile-box/profile-box.component";
import {AuditBoxComponent} from "./secured/project/audit-box/audit-box.component";
import {SkillsComponent} from "./public/skills/skills.component";

@NgModule({
  declarations: [
    AppComponent,
    PublicComponent,
    SecuredComponent,
    LoginComponent,
    MenuComponent,
    FooterComponent,
    HeaderComponent,
    NewsBoxComponent,
    CurrentProjectLinksComponent,
    NavigationComponent,
    ModalComponent,
    ProjectComponent,
    ResourcesBoxComponent,
    TasksBoxComponent,
    OffProjectComponent,
    DashboardComponent,
    ProjectBoxComponent,
    OffProjectBoxComponent,
    DigitalMaturityBoxComponent,
    AddResourceComponent,
    MeetingListComponent
  ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        ReactiveFormsModule,
        HttpClientModule,
        BrowserAnimationsModule,
        NgxPaginationModule,
        ToastrModule.forRoot({
            closeButton: true,
            positionClass: "toast-top-center",
            timeOut: 2000,
            easing: "ease-in-out",
            easeTime: 200,
            progressBar: true,
            progressAnimation: 'increasing'
        }),
        PresentationComponent,
        ServiceListComponent,
        ContactComponent,
        OffersComponent,
        TimeBoxComponent,
        SubscriptionBoxComponent,
        ProfileBoxComponent,
        AuditBoxComponent,
        SkillsComponent
    ],
  bootstrap: [AppComponent],
  providers: [
    provideClientHydration()
  ]
})
export class AppModule { }
