import { Component } from '@angular/core';
import {RouterLink} from "@angular/router";
import {TranslateService} from "../../../services/translate.service";

@Component({
  selector: 'app-profile-box',
  standalone: true,
    imports: [
        RouterLink
    ],
  templateUrl: './profile-box.component.html',
  styleUrl: './profile-box.component.scss'
})
export class ProfileBoxComponent {
  constructor(public translateService: TranslateService) {
  }
}
