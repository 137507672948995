<h2>{{ translateService.translate("signin") }}</h2>
<form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
  <label for="username">email</label>
  <input formControlName="email" id="username" type="text" #username>

  <label for="password">{{translateService.translate('password')}}</label>
  <input formControlName="password" id="password" type="password" #password>

  <label *ngIf="signupMode" for="repeat-password">{{translateService.translate('repeat-password')}}</label>
  <input formControlName="repeatpassword"  *ngIf="signupMode" id="repeat-password" type="password" #repeatPassword>
  <span class="submit-container">
  <button class="btn-clear" type="button" (click)="toggleMode()">{{signupMode ? translateService.translate("switch-to-login") : translateService.translate("switch-to-signup") }}</button>
  <button class="btn-primary" type="submit" [disabled]="!checkPasswords()">{{signupMode ? translateService.translate("signup") : translateService.translate("signin") }}</button>
  </span>
  <div *ngIf="signupMode" class="pwd-check" [innerHTML]="displayPasswordCheck()"></div>
</form>
