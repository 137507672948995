import {Component, OnInit} from '@angular/core';
import {CommonService} from "../../services/common.service";

@Component({
  selector: 'app-subscription',
  standalone: true,
  imports: [],
  templateUrl: './subscription.component.html',
  styleUrl: './subscription.component.scss'
})
export class SubscriptionComponent implements OnInit {
  title:string = 'Your subscription';
  constructor(private commonService: CommonService) {
  }
  ngOnInit() {
    this.commonService.setCurrentPageTitle($localize `${this.title}`);
  }

}
