<div class="container">

  <h1>{{countTimesheetHours('ongoing')}}h en cours</h1>
  <ul class="ongoing-off-project-content">
    <li class="frame" *ngFor="let timesheet of getTimesheets('to pay')| paginate: { id: 'p1',itemsPerPage: tasksPerPage, currentPage: pageOngoing }">
      <span class="creation-date">{{timesheet.creationDate | date : 'dd/MM/yy'}}</span>
      <span class="task-time">{{timesheet.hours}}h</span>
      <span class="task-price">{{timesheet.rate * timesheet.hours}}€</span>
      <span class="task-name">{{timesheet.name}}</span></li>
  </ul>

  <pagination-template
    #p1="paginationApi"
    id="p1"
    (pageChange)="pageOngoing = $event"
    *ngIf="getTimesheets('to pay').length>tasksPerPage">
    <div class="tasks-pagination">
      <ul>
        <li class="pagination-nav-item">
          <a *ngIf="!p1.isFirstPage()"
             [class.disabled]="p1.isFirstPage()"
             (click)="p1.previous()"
             class="page-link"
             aria-label="Préc.">
            <span aria-hidden="true" class="la la-caret-left"></span>
            <span class="sr-only">Préc.</span>
          </a>
          <a *ngIf="p1.isFirstPage()"
             class="page-link disabled"
             aria-label="Préc.">
            <span aria-hidden="true" class="la la-caret-left"></span>
            <span class="sr-only">Préc.</span>
          </a>
        </li>
        <li *ngFor="let page of p1.pages"
            [class.active]="p1.getCurrent() === page.value"
            class="pagination-item">
          <a (click)="p1.setCurrent(page.value)"
             class="page-link">  {{ page.label }}
          </a>
        </li>
        <li class="pagination-nav-item">
          <a *ngIf="!p1.isLastPage()"
             (click)="p1.next()"
             class="page-link"
             aria-label="Suiv.">
            <span aria-hidden="true" class="la la-caret-right"></span>
            <span class="sr-only">Suiv.</span>
          </a>
          <a *ngIf="p1.isLastPage()"
             class="page-link disabled"
             aria-label="Suiv.">
            <span aria-hidden="true" class="la la-caret-right"></span>
            <span class="sr-only">Suiv.</span>
          </a>
        </li>
      </ul>
    </div>
  </pagination-template>
  <span>
  </span>
  <div class="closed-tasks">
    <span>
    <h1>{{countTimesheetHours('closed')}}h clôturé</h1>
    <ul class="closed-off-project-content">
      <li *ngFor="let task of getTimesheets('paid')| paginate: { id: 'p2', itemsPerPage: tasksPerPage, currentPage: pageClosed }">
        <span class="creation-date">{{task.creationDate | date : 'dd/MM/yy'}}</span>
        <span class="task-time">{{task.hours}}h</span>
        <span class="task-name">{{task.name}}</span></li>
    </ul>

    <pagination-template
      #p2="paginationApi"
      id="p2"
      (pageChange)="pageClosed = $event"
      *ngIf="getTimesheets('paid').length>tasksPerPage">
      <div class="tasks-pagination">
        <ul>
          <li class="pagination-nav-item">
            <a *ngIf="!p2.isFirstPage()"
               [class.disabled]="p2.isFirstPage()"
               (click)="p2.previous()"
               class="page-link"
               aria-label="Préc.">
              <span aria-hidden="true" class="la la-caret-left"></span>
            </a>
            <a *ngIf="p2.isFirstPage()"
               class="page-link disabled"
               aria-label="Préc.">
              <span aria-hidden="true" class="la la-caret-left"></span>
            </a>
          </li>
          <li *ngFor="let page of p2.pages"
              [class.active]="p2.getCurrent() === page.value"
              class="pagination-item">
            <a (click)="p2.setCurrent(page.value)"
               class="page-link">
            </a>
          </li>
          <li class="pagination-nav-item">
            <a *ngIf="!p2.isLastPage()"
               (click)="p2.next()"
               class="page-link"
               aria-label="Suiv.">
              <span aria-hidden="true" class="la la-caret-right"></span>
            </a>
            <a *ngIf="p2.isLastPage()"
               class="page-link disabled"
               aria-label="Suiv.">
              <span aria-hidden="true" class="la la-caret-right"></span>
            </a>
          </li>
        </ul>
      </div>
    </pagination-template>
    </span>
    <span>
      TEST
    </span>

  </div>

</div>
