<div class="digital-maturity">
  <h2>Maturité digitale de votre entreprise</h2>
  <div class="indicator">
    <span class="digital-label">Stratégie globale</span>
    <span class="score">2/5</span>
    <progress value="40" max="100"> 40% </progress>
  </div>
  <div class="indicator">
    <span class="digital-label">Culture du numérique</span>
    <span class="score">2/5</span>
    <progress value="40" max="100"> 40% </progress>
  </div>
  <div class="indicator">
    <span class="digital-label">Sécurité</span>
    <span class="score">2/5</span>
    <progress value="40" max="100"> 40% </progress>
  </div>
  <div class="indicator">
    <span class="digital-label">Innovation</span>
    <span class="score">2/5</span>
    <progress value="40" max="100"> 40% </progress>
  </div>
</div>
