import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {catchError, EMPTY, map, Subscription} from "rxjs";
import {Resource} from "../models/resource";
import {CommonService} from "./common.service";
import {EnvironmentService} from "./environment.service";

@Injectable({
  providedIn: 'root'
})
export class ResourceService {

  userResources: Resource[] =[];
  project:string = "";
  // @ts-ignore
  getResourcesSubscription:Subscription;

  constructor(private commonService: CommonService, private environmentService: EnvironmentService, private http: HttpClient) {}


  notionJsonToResource(json:any) {
    let tempResource: Resource = {
      id : json.id,
      name : json["properties"]["Name"]["title"][0]["plain_text"],
      type : json["properties"]["Type"]["select"]["name"],
      resources : [],
      current_version: 1,
      owner_id: 'test',
      projectId : json["properties"]["Project"]["relation"][0]["id"],
      createdAt: new Date().toString(),
      tags: json["properties"]["Tags"]["multi_select"],
      url : json["properties"]["URL"]["url"] ?json["properties"]["URL"]["url"] : "",
    }
    return tempResource;
  }

  retrieveResources(customerId:string) {
    this.getResourcesSubscription = this.getResourcesFromNotion(customerId).subscribe();
  }


  getResourcesFromNotion(customerId:string = "test") {
    this.commonService.enableLoader();
    const headers = { 'content-type': 'application/json', 'Authorization': 'Bearer secret_7zOrfx8Ut1YwwCt4u06dkDYB9c2LqucGQSU2f5fZK4G', 'Notion-Version': '2022-06-28'};

    const requestJSON ={
      "filter": {
        "property": "Company",
        "relation": {
          "contains": customerId
        }
      }};

    const body=JSON.stringify(requestJSON);


    return this.http.post(this.environmentService.getNotionUrl('getNotionResources'),body, {headers})
      .pipe(map(res => {
          // @ts-ignore
          for (let resource of res["results"]) {
            this.userResources.push(this.notionJsonToResource(resource));
          }
          this.commonService.disableLoader();

        }),
        catchError((err) => {
          console.log('error retrieving resources', err);
          this.commonService.disableLoader();
          return EMPTY;
        }));

  }

  getResourceById(resourceId:string) {

    for (let resource of this.userResources) {
      if (resource.id == resourceId) return resource;
    }
    let tempResource: Resource = {
      id : '-1',
      name : 'empty',
      type : 'pdf',
      resources : [],
      current_version:1,
      owner_id: 'test',
      createdAt: new Date().toString()
    }
    return tempResource;
  }

  emptyResources() {
    this.userResources = [];
    this.getResourcesSubscription.unsubscribe();
  }
}
