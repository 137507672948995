<!-- Modal that pops up when you click on "New Message" -->
<div class="modal-box">
  <div class="modal-content tally" *ngIf="modalService.modalType == 'ask-quote'">
    <span class="close-btn" (click)="modalService.hideModal()">
      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><path fill="#05A596" d="M16 2C8.2 2 2 8.2 2 16s6.2 14 14 14s14-6.2 14-14S23.8 2 16 2m5.4 21L16 17.6L10.6 23L9 21.4l5.4-5.4L9 10.6L10.6 9l5.4 5.4L21.4 9l1.6 1.6l-5.4 5.4l5.4 5.4z"/></svg>
    </span>
    <iframe [src]="askQuoteUrl" loading="lazy" width="100%" height="100%" title="Quote request"></iframe>
  </div>
  <div class="modal-content contact-form" *ngIf="modalService.modalType == 'former-contact'">
    <span class="close-btn" (click)="modalService.hideModal()">
      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><path fill="#ffffff" d="M16 2C8.2 2 2 8.2 2 16s6.2 14 14 14s14-6.2 14-14S23.8 2 16 2m5.4 21L16 17.6L10.6 23L9 21.4l5.4-5.4L9 10.6L10.6 9l5.4 5.4L21.4 9l1.6 1.6l-5.4 5.4l5.4 5.4z"/></svg>
    </span>
    <form id="fs-frm" name="simple-contact-form" accept-charset="utf-8" action="https://formspree.io/f/xeqyaykr" method="post">
      <fieldset id="fs-frm-inputs">
        <label for="full-name">{{translateService.translate("full-name")}}</label>
        <input type="text" name="name" id="full-name" [placeholder]="translateService.translate('first-last')" required>
        <label for="email-address" i18n>{{ translateService.translate("email-address") }}</label>
        <input type="email" name="_replyto" id="email-address" placeholder="email@domain.tld" required>
        <label for="message">Message</label>
        <textarea rows="5" name="message" id="message" [placeholder]="translateService.translate('i-would-like-more-info')" required></textarea>
        <input type="hidden" name="_subject" id="email-subject" value="Contact Form Submission">

        <input type="submit" [value]="translateService.translate('submit')"><br/>
        <button class="btn-clear" (click)="modalService.hideModal()">{{ translateService.translate('cancel') }}</button>
      </fieldset>
    </form>
  </div>
  <div class="modal-content login" *ngIf="modalService.modalType == 'login'">
    <span class="close-btn" (click)="modalService.hideModal()">
      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><path fill="#ffffff" d="M16 2C8.2 2 2 8.2 2 16s6.2 14 14 14s14-6.2 14-14S23.8 2 16 2m5.4 21L16 17.6L10.6 23L9 21.4l5.4-5.4L9 10.6L10.6 9l5.4 5.4L21.4 9l1.6 1.6l-5.4 5.4l5.4 5.4z"/></svg>
    </span>
    <app-login />
  </div>
  <div class="modal-content subscription" *ngIf="modalService.modalType == 'subscription-box'">
    <span class="close-btn" (click)="modalService.hideModal()">
      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><path fill="#ffffff" d="M16 2C8.2 2 2 8.2 2 16s6.2 14 14 14s14-6.2 14-14S23.8 2 16 2m5.4 21L16 17.6L10.6 23L9 21.4l5.4-5.4L9 10.6L10.6 9l5.4 5.4L21.4 9l1.6 1.6l-5.4 5.4l5.4 5.4z"/></svg>
    </span>
    <app-subscription-box />
  </div>

  <div class="modal-content tally" *ngIf="modalService.modalType == 'subscription'">
    <span class="close-btn" (click)="modalService.hideModal()">
      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><path fill="#05A596" d="M16 2C8.2 2 2 8.2 2 16s6.2 14 14 14s14-6.2 14-14S23.8 2 16 2m5.4 21L16 17.6L10.6 23L9 21.4l5.4-5.4L9 10.6L10.6 9l5.4 5.4L21.4 9l1.6 1.6l-5.4 5.4l5.4 5.4z"/></svg>
    </span>
    <iframe [src]="getFullSubscriptionUrl()" loading="lazy" width="100%" height="100%" [title]="translateService.translate('subscription')"></iframe>
  </div>

  <div class="modal-content tally" *ngIf="modalService.modalType == 'contact'">
    <span class="close-btn" (click)="modalService.hideModal()">
      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><path fill="#05A596" d="M16 2C8.2 2 2 8.2 2 16s6.2 14 14 14s14-6.2 14-14S23.8 2 16 2m5.4 21L16 17.6L10.6 23L9 21.4l5.4-5.4L9 10.6L10.6 9l5.4 5.4L21.4 9l1.6 1.6l-5.4 5.4l5.4 5.4z"/></svg>
    </span>
    <iframe [src]="getContactUrl()" loading="lazy" width="100%" height="100%" title="Contact"></iframe>
  </div>

  <div class="modal-content meeting" *ngIf="modalService.modalType == 'meeting-minute'">
    <span class="close-btn" (click)="modalService.hideModal()">
      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><path fill="#05A596" d="M16 2C8.2 2 2 8.2 2 16s6.2 14 14 14s14-6.2 14-14S23.8 2 16 2m5.4 21L16 17.6L10.6 23L9 21.4l5.4-5.4L9 10.6L10.6 9l5.4 5.4L21.4 9l1.6 1.6l-5.4 5.4l5.4 5.4z"/></svg>
    </span>
    <iframe [src]="getMeetingMinuteUrl()" loading="lazy" width="100%" height="100%" title="Meeting Minute"></iframe>
  </div>

  <div class="modal-content video" *ngIf="modalService.modalType == 'digital-audit'">
    <span class="close-btn" (click)="modalService.hideModal()">
      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><path fill="#05A596" d="M16 2C8.2 2 2 8.2 2 16s6.2 14 14 14s14-6.2 14-14S23.8 2 16 2m5.4 21L16 17.6L10.6 23L9 21.4l5.4-5.4L9 10.6L10.6 9l5.4 5.4L21.4 9l1.6 1.6l-5.4 5.4l5.4 5.4z"/></svg>
    </span>
    <video width="100%" autoplay controls>
      <source [src]="'assets/video/' + translateService.translate('mp4-digital-audit')" type="video/mp4">
      <span>{{translateService.translate('no-support-browser')}}</span>
    </video>
  </div>

  <div class="modal-content video" *ngIf="modalService.modalType == 'webdev'">
    <span class="close-btn" (click)="modalService.hideModal()">
      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><path fill="#05A596" d="M16 2C8.2 2 2 8.2 2 16s6.2 14 14 14s14-6.2 14-14S23.8 2 16 2m5.4 21L16 17.6L10.6 23L9 21.4l5.4-5.4L9 10.6L10.6 9l5.4 5.4L21.4 9l1.6 1.6l-5.4 5.4l5.4 5.4z"/></svg>
    </span>
    <video width="100%" autoplay controls>
      <source src="assets/video/advanced-web-development.mp4" type="video/mp4">
      <span>{{translateService.translate('no-support-browser')}}</span>
    </video>
  </div>




  <div class="modal-content" *ngIf="modalService.modalType == 'default'">
    <div>
       <span (click)="modalService.hideModal()">Fermer</span>
      <h2>Send Mail</h2>
    </div>
    <div>
      <label>To</label><br>
      <input type="text"><br>
      <label>From</label><br>
      <input type="text"><br>
      <label>Subject</label><br>
      <input type="text"><br>
      <textarea>

      </textarea>
      <div>
        <button class="btn-clear" (click)="modalService.hideModal()">Cancel</button><br/>
        <button (click)="modalService.hideModal()" >Send</button>
      </div>
    </div>
  </div>
</div>
