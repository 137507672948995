import {CanActivateFn, Router} from '@angular/router';
import {inject} from "@angular/core";
import {AuthService} from "../services/auth.service";

export const authGuard: CanActivateFn = (route, state) => {
  const authService = inject(AuthService);
  const router = inject(Router);
  return true;
  //TODO uncomment
  /*if (!authService.userIsAuthenticated) router.navigateByUrl('/public');
  return authService.userIsAuthenticated;*/
};
