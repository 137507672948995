import { Component } from '@angular/core';

@Component({
  selector: 'app-technical-review',
  standalone: true,
  imports: [],
  templateUrl: './technical-review.component.html',
  styleUrl: './technical-review.component.scss'
})
export class TechnicalReviewComponent {

}
