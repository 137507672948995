import {Component, OnInit} from '@angular/core';
import {AuthService} from "../services/auth.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {PasswordService} from "../services/password.service";
import {TranslateService} from "../services/translate.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  // @ts-ignore
  loginForm: FormGroup;
  formAction = 'signIn';
  signupMode: boolean = false;

  constructor(public formBuilder: FormBuilder,
              public passwordService: PasswordService,
              public translateService: TranslateService,
              private authService: AuthService,
              private router: Router) { }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(2)]],
      repeatpassword: ['', [Validators.minLength(2)]],
      usertype: ['Free', [Validators.required]],
      email: ['', [Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')]]
    });
  }

  onSubmit() {
    if (this.signupMode)
    {
      this.onSignUp();
    }
    else {
      this.onLogin();
    }
  }
  onLogin() {
    this.authService.login(this.loginForm?.value.email, this.loginForm?.value.password);
  }
  onSignUp() {
    this.authService.signUp(this.loginForm?.value.email, this.loginForm?.value.password,this.loginForm?.value.usertype);
  }
  switchToSignup() {
    this.formAction = 'signUp';
  }

  toggleMode():void {
    this.signupMode = !this.signupMode;
  }

  displayPasswordCheck() {
    return this.passwordService.displayCheckPasswordsResults(this.loginForm?.value.password, this.loginForm?.value.repeatpassword)
  }
  checkPasswords() {
    return (this.signupMode && this.passwordService.checkPasswords(this.loginForm?.value.password, this.loginForm?.value.repeatpassword)) || !this.signupMode;
  }

}
