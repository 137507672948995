<div class="container">
  <div class="password-box frame">
    <h2 i18n>Change your password</h2>
    <form class="change-password-form" (keyup)="enablePwdCheck()">
      <label for="password" i18n>password</label><br>
      <input type="password" id="password" #pwd1><br>
      <label for="repeat" i18n>repeat</label><br>
      <input type="password" id="repeat" #pwd2><br>
      <button class="btn-primary btn-large" type="button" (click)="onSubmit(pwd1.value, pwd2.value)" [class.is-active]="passwordService.checkPasswords(pwd1.value, pwd2.value)" i18n>Confirm new password</button>
      <div *ngIf="displayPwdCheck()" class="pwd-check" [innerHTML]="passwordService.displayCheckPasswordsResults(pwd1.value, pwd2.value)"></div>
    </form>
  </div>
</div>
