import {Component, OnInit} from '@angular/core';
import {Project} from "../../models/project";
import {Resource} from "../../models/resource";
import {ActivatedRoute} from "@angular/router";
import {ProjectService} from "../../services/project.service";
import {CommonService} from "../../services/common.service";
import {Audit} from "../../models/audit";
import {AuditService} from "../../services/audit.service";

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss']
})
export class ProjectComponent implements OnInit {
  currentTab : string = "tasks";
  constructor(private route: ActivatedRoute,
              public projectService: ProjectService,
              private auditService:AuditService,
              public commonService: CommonService) { this.commonService.enableLeftMenu();}

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.project = this.projectService.getProjectById(params["id"]);
      this.commonService.setCurrentPageTitle(this.project?.name?this.project.name:"");
      if (this.project?.project_type == 'Audit') {
        this.currentTab = 'diagnostic'
      }
      else {
        this.currentTab = 'tasks'
      }
    })
  }

  project : Project | null = {
    id: '1',
    name: "the project",
    taskList: [
      {
        id:'0',
        name: 'tache une',
        status : 'ongoing',
        creationDate : new Date()
      },
      {
        id:'1',
        name: 'tache deux',
        status : 'ongoing',
        creationDate : new Date()
      },
      {
        id:'2',
        name: 'tache trois',
        status : 'done',
        creationDate : new Date()
      }],
    resources:[
      {
        id : '0',
        name : 'example of document',
        type: 'file',
        resources : [],
        tags : [
          {
            id:0,
            name:'tag1'
          },
          {
            id:1,
            name:'tag2'
          }
        ],
        current_version:1,
        owner_id: '0',
        createdAt: 'today'
      },
      {
        id : '1',
        name : 'example of link',
        type: 'link',
        resources : [],
        tags : [
          {
            id:1,
            name:'tag2'
          },
          {
            id:2,
            name:'tag3'
          }
        ],
        current_version:1,
        owner_id: '0',
        createdAt: 'today'
      },
      {
        id : '2',
        name : 'example of image',
        type: 'img',
        resources : [],
        tags : [
          {
            id:0,
            name:'tag1'
          },
          {
            id:2,
            name:'tag3'
          }
        ],
        current_version:1,
        owner_id: '0',
        createdAt: 'today'
      },
      {
        id : '3',
        name : 'example of note',
        type: 'note',
        resources : [],
        tags : [
          {
            id:0,
            name:'tag1'
          },
          {
            id:2,
            name:'tag3'
          }
        ],
        current_version:1,
        owner_id: '0',
        createdAt: 'today'
      }
    ],
    status: "ongoing", // todo, ongoing, done, archived, canceled
    id_customer : "1"
  }

  getTaskList(filter = 'all') {
    if (filter === 'all') return this.getProjectTaskList();
    let tempList = [];
    for (let task of this.getProjectTaskList()) {
      if (task.status === filter) tempList.push(task);
    }
    return tempList;
  }

  getProgress() {
    return this.getTaskList() !=null ? Math.round(100 - ((this.getTaskList('todo').length + this.getTaskList('ongoing').length)*100 / this.getTaskList().length)) : 0;
  }
  getProjectTaskList () {
    return this.project?this.project.taskList:[];
  }

  getProjectResources():Resource[] {
    return this.project?this.project.resources:[];
  }

  getProjectAudit():Audit {
    console.log("project -> getProjectAudit", this.project?.audit_id);
    // @ts-ignore
    return this.auditService.getAuditById(this.project?.audit_id);
  }

  setTab(tab:string) {
    this.currentTab = tab;
  }
  isActive(tab:string) {
    return (this.currentTab === tab);
  }
}
