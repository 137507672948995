import {Component, Input} from '@angular/core';
import {Meeting} from "../../../models/meeting";
import {MeetingService} from "../../../services/meeting.service";
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import {ModalService} from "../../../services/modal.service";
import {TranslateService} from "../../../services/translate.service";

@Component({
  selector: 'app-meeting-list',
  templateUrl: './meeting-list.component.html',
  styleUrls: ['./meeting-list.component.scss']
})
export class MeetingListComponent {
  @Input() meetings:Meeting[] = [];
  @Input() isSidebar:boolean = false;
  page = 1;
  meetingsPerPage = 3;
  meetingVisible:boolean = false;
  currentMeeting:any = {
    name : "no name",
    meetingDate : new Date(),
    meetingUrl : ""
  };
  public safeSrc: SafeResourceUrl | undefined = this.sanitizer.bypassSecurityTrustResourceUrl(this.currentMeeting.minuteURL);

  constructor(public translateService: TranslateService,
              private meetingService: MeetingService,
              private modalService: ModalService,
              private sanitizer: DomSanitizer) {
  }
  getMeetings() {
    return this.meetingService.getMeetings();
  }

  showMeeting(meeting:any) {
    if (meeting.minuteURL !== '') {
      this.modalService.showModal('meeting-minute', meeting);
      this.currentMeeting = meeting;
      this.safeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(meeting.minuteURL);
    }
  }
  hideMeeting() {
    this.meetingVisible = false;
  }

}
