import {Component, Input} from '@angular/core';
import {Timesheet} from "../../../models/timesheet";
import {TimesheetService} from "../../../services/timesheet.service";
import {TranslateService} from "../../../services/translate.service";

@Component({
  selector: 'app-off-project-box',
  templateUrl: './off-project-box.component.html',
  styleUrls: ['./off-project-box.component.scss']
})
export class OffProjectBoxComponent {
  @Input() timesheets:Timesheet[] = [];
  constructor(public translateService: TranslateService, private timesheetService: TimesheetService) {}

  countTimesheetHours(filter:string) {
    return this.timesheetService.countTimesheetHours(filter);
  }
  getTimesheets(filter:string = 'all') {
    if (filter != 'all') {
      let tempTimesheets = [];

      for (let timesheet of this.timesheets) {
        if (timesheet.status == filter) tempTimesheets.push(timesheet)
      }
      return tempTimesheets;
    }
    return this.timesheets;
  }
}
