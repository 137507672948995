import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  _isLeftMenuVisible: boolean;
  _currentPage:string;
  _isLoading:boolean;
  _elementLoading:number;
  constructor() { this._isLeftMenuVisible = false; this._isLoading = false; this._elementLoading = 0; this._currentPage = "Dashboard"; }

  enableLeftMenu() {
    this._isLeftMenuVisible = true;
  }

  disableLeftMenu() {
    this._isLeftMenuVisible = false;
  }

  get isLeftMenuVisible() {
    return this._isLeftMenuVisible;
  }

  enableLoader() {this._elementLoading ++;this._isLoading = true;}
  disableLoader() {this._elementLoading --; this._isLoading = this._elementLoading > 0;}
  get isLoading() {return this._isLoading;}

  getCurrentPageTitle() {
    return this._currentPage;
  }
  setCurrentPageTitle(title:string) {
    this._currentPage = title;
  }

  HTMLReturn(str:string) {
    return str.replace(/\n/g, "<br />");
  }
}
