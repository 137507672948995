import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Router} from "@angular/router";
import {User} from "../models/user";
import {ToastrService} from "ngx-toastr";
import {catchError, EMPTY, map} from "rxjs";
import {ProjectService} from "./project.service";
import {TimesheetService} from "./timesheet.service";
import {TaskService} from "./task.service";
import {ResourceService} from "./resource.service";
import {MeetingService} from "./meeting.service";
import {ModalService} from "./modal.service";
import {AuditService} from "./audit.service";
import {CommonService} from "./common.service";
import {EnvironmentService} from "./environment.service";
import {AnalyticsService} from "./analytics.service";
import {environment} from "../../environments/environment";
import {TranslateService} from "./translate.service";
import {QuoteService} from "./quote.service";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  _userIsAuthenticated = false;
  _userToken ="";

  // @ts-ignore
  _currentUser : User;

  get userIsAuthenticated() {
    return this._userIsAuthenticated;
  }

  get currentUser():any {return this._currentUser;}
  constructor(public translateService: TranslateService,
              private analyticsService : AnalyticsService,
              private environmentService: EnvironmentService,
              private http:HttpClient,
              private router:Router,
              private toastr: ToastrService,
              private auditService: AuditService,
              private projectService: ProjectService,
              private quoteService: QuoteService,
              private taskService: TaskService,
              private resourceService: ResourceService,
              private timesheetService: TimesheetService,
              private meetingService: MeetingService,
              private modalService: ModalService,
              private commonService : CommonService) { }

  login(email:string, password:string) {
    this.commonService.enableLoader();
    let loginObject = {"email": email, "password":password};
    let stringLoginObject = JSON.stringify(loginObject);
    this.http.post(this.environmentService.getApiRequest('login'), stringLoginObject)
      .pipe(map(res => {
        // @ts-ignore
        if (res['token'] !== null ){
          this._userIsAuthenticated = true;
          // @ts-ignore
          this._userToken = res['token'] ;
          // @ts-ignore
          this._currentUser = res['user'];
          this.timesheetService.getTimesheetFromNotion(this._currentUser.trqid).subscribe();
          this.meetingService.getMeetingFromNotion(this._currentUser.trqid).subscribe();
          this.taskService.getTasksFromNotion(this._currentUser.trqid).subscribe();
          this.quoteService.retrieveQuotes();
          this.projectService.getProjectsFromNotion(this._currentUser.trqid).subscribe();
          this.modalService.hideModal();
          this.toastr.success(this.translateService.translate("login-successful"));

          this.router.navigateByUrl('/secured').then(() => {
            window.scroll({
              top: 0,
              left: 0,
              behavior: 'smooth'
            });
            this.commonService.disableLoader();
          });

          this.analyticsService.trackEvent(environment.gaEvt.login.name, environment.gaEvt.login.details, environment.gaEvt.login.category);
        }
        else {
          this._userIsAuthenticated = false;
          this._userToken = "";
          this.timesheetService.emptyTimesheet();
          this.meetingService.emptyMeetings();
          this.taskService.emptyTasks();
          this.resourceService.emptyResources();
          this.auditService.emptyAudits();
          this.quoteService.emptyQuotesSubscription();
          // @ts-ignore
          this.toastr.error(res['message']);
          this.router.navigateByUrl('/public').then(()=> {
            this.commonService.disableLoader();
          });
        }

      }),
        catchError((err) => {
          this.toastr.error(err);
          return EMPTY;
        }))
      .subscribe();
  }

  signUp(email:string, password:string, pack:string = 'Free', usertype:string = 'user' ) {
    const trqid = this.generateTrqid(50);


    let registerObject = {lastname:'---', firstname:'---', trqid:trqid, usertype:usertype, currentPack: pack,pseudo:email, email : email, password: password };
    let stringRegisterObject = JSON.stringify(registerObject);

    const headers = { 'content-type': 'application/json', 'Authorization': 'Bearer secret_7zOrfx8Ut1YwwCt4u06dkDYB9c2LqucGQSU2f5fZK4G', 'Notion-Version': '2022-06-28'};

    const requestJSON =
    {
      "parent": { "database_id": "055d16ea741e482ead5634c00ef72ea1" },
      "properties": {
        "Firstname": {
          "title": [
            {
              "text": {
                "content": "---"
              }
            }
          ]
        },
        "Lastname": {
          "rich_text": [
            {
              "type": "text",
              "text": {
                "content": "---",
                "link": null
              },
              "annotations": {
                "bold": false,
                "italic": false,
                "strikethrough": false,
                "underline": false,
                "code": false,
                "color": "green"
              },
              "plain_text": "---",
              "href": null
            }
          ]
        },
        "Email": {
          "email": email
        },
        "Trqid": {
          "rich_text": [
            {
              "type": "text",
              "text": {
                "content": trqid,
                "link": null
              },
              "annotations": {
                "bold": false,
                "italic": false,
                "strikethrough": false,
                "underline": false,
                "code": false,
                "color": "green"
              },
              "plain_text": trqid,
              "href": null
            }
          ]
        }
    }
    };
    const body=JSON.stringify(requestJSON);

    this.http.post(this.environmentService.getNotionUrl('addNotionUser'),body, {headers})
      .pipe(map(res => {
          this.analyticsService.trackEvent(environment.gaEvt.sign_up.name, environment.gaEvt.sign_up.details, environment.gaEvt.sign_up.category);
        }),
        catchError((err) => {
          console.log(err);
          return EMPTY;
        }))
      .subscribe();



    this.http.post(this.environmentService.getApiRequest('register'), stringRegisterObject)
      .pipe(map(() => {
            // @ts-ignore
            this.login(email, password);
  }),
    catchError((err) => {
      this.router.navigateByUrl('/public').then(() => {
        this.toastr.error(err.error['message']);
        this._userIsAuthenticated = false;
        this._userToken = "";
      });
      return EMPTY;
    }))
    .subscribe();
  }

  logout() {

    this.router.navigateByUrl('/public').then(() => {
      this.toastr.info(this.translateService.translate("signed-out"));
      this.analyticsService.trackEvent(environment.gaEvt.logout.name, environment.gaEvt.logout.details, environment.gaEvt.logout.category);
    });

    this._userIsAuthenticated = false;
    this._userToken = "";

    this._currentUser = {
      email : '',
      firstname : '',
      id : 0,
      lastname : '',
      organizations : [],
      pseudo: '',
      trqid : '',
      usertype : '',
      currentPack:''
    };
    this.projectService.emptyUserProjectList();
    this.timesheetService.emptyTimesheet();
    this.meetingService.emptyMeetings();
    this.taskService.emptyTasks();
    this.resourceService.emptyResources();
    this.auditService.emptyAudits();
    this.quoteService.emptyQuotesSubscription();
  }

  generateTrqid(length:number) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }

  updatePassword(newPwd:string) {
      this.commonService.enableLoader();

    let updateObject = {"email": this.currentUser.email, "password":newPwd};
    let stringUpdateObject = JSON.stringify(updateObject);

    let myHeaders: HttpHeaders = new HttpHeaders();
    myHeaders.set("Content-Type", "application/json; charset=UTF-8");
    myHeaders.append("Authorization", 'Bearer ' + this._userToken);


    const requestOptions = {
      headers: {"Content-Type": "application/json; charset=UTF-8", "Authorization": 'Bearer ' + this._userToken},
      body: updateObject
    };
    let stringRequestOptions = JSON.stringify(requestOptions);

      return this.http.post(this.environmentService.getApiRequest('updatePwd'), stringRequestOptions)
        .pipe(map(res => {
            this.toastr.success(this.translateService.translate("password-updated"));
            this.commonService.disableLoader();
          }),
          catchError((err) => {
            this.toastr.error(this.translateService.translate("password-not-updated"));
            this.commonService.disableLoader();
            return EMPTY;
          }))
        .subscribe();

  }


  currentMonthDays() {
    const today = new Date();
    const registrationCurrentPackDate = new Date(this.currentUser.currentPackRegistrationDate);

    if (today.getDate() > registrationCurrentPackDate.getDate()) {
      return today.getDate() - registrationCurrentPackDate.getDate();
    }
    else {
      let lastMonth = today.getMonth();
      let anniversaryDate = new Date(today.getFullYear() + '-' + lastMonth + '-' + registrationCurrentPackDate.getDate());
      return Math.floor(Math.abs(today.getTime() - anniversaryDate.getTime())/(1000 * 60 * 60 * 24));
    }
  }

  getPreviousAnniversaryDate() {

    const today = new Date();
    const registrationCurrentPackDate = new Date(this.currentUser.currentPackRegistrationDate);

    if (today.getDate() > registrationCurrentPackDate.getDate()) {
      return new Date(today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + registrationCurrentPackDate.getDate());
    }
    else {
      let lastMonth = today.getMonth();
      return new Date(today.getFullYear() + '-' + lastMonth + '-' + registrationCurrentPackDate.getDate());
    }
  }

  daysSinceAnniversary(): number {
    const registrationCurrentPackDate = new Date(this.currentUser.currentPackRegistrationDate);
    const today = new Date();
    const anniversaryThisYear = new Date(today.getFullYear(), registrationCurrentPackDate.getMonth(), registrationCurrentPackDate.getDate());

    if (today < anniversaryThisYear) {
      anniversaryThisYear.setFullYear(today.getFullYear() - 1);
    }

    return Math.floor((today.getTime() - anniversaryThisYear.getTime()) / (1000 * 60 * 60 * 24));
  }
  daysUntilPreviousAnniversary(): number {
    const createdAt = new Date(this.currentUser.currentPackRegistrationDate);
    const today = new Date();
    const previousAnniversary = new Date(today.getFullYear(), createdAt.getMonth(), createdAt.getDate());

    if (today < previousAnniversary) {
      previousAnniversary.setFullYear(today.getFullYear() - 1);
    }

    const differenceInDays = Math.ceil((today.getTime() - previousAnniversary.getTime()) / (1000 * 60 * 60 * 24));
    return differenceInDays;
  }
}
