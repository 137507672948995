import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {catchError, EMPTY, map, Subscription} from "rxjs";
import {Package} from "../models/package";
import {EnvironmentService} from "./environment.service";

@Injectable({
  providedIn: 'root'
})
export class PackageService {

  packages: Package[] =[];
  packageSubscription: Subscription | undefined;
  constructor(private environmentService: EnvironmentService, private http: HttpClient) {
  }

  initPackages() {
    this.packageSubscription = this.getPackagesFromNotion().subscribe();
  }

  getPackages(isActive? : boolean) {
    if (isActive !== null) { return this.packages; }
    else {
      let tempPackageArray = [];
      for (let pack of this.packages) {
        if (pack.isActive == isActive ) tempPackageArray.push(pack);
      }
      return tempPackageArray;
    }
  }
  jsonToPackage(json:any) {
    let tempPackage: Package = {
      id: json.id,
      isActive:json["properties"]["is Active"]["checkbox"],
      callHours:json["properties"]["Call hours"]["number"],
      codingHours: json["properties"]["Coding hours"]["number"],
      price:json["properties"]["Price"]["number"],
      services: [],
      services_en : [],
      services_fr : [],
      title: json["properties"]["Title"]["rich_text"][0]["plain_text"]
    }

    let tempServices = [];
    for (let service of json["properties"]["Services"]["multi_select"]) {
      tempServices.push(service["name"]);
    }
    tempPackage.services = tempServices;

    tempServices = [];
    for (let service of json["properties"]["Services_fr_list"]["formula"]["string"].split(", ")) {
      tempServices.push(service);
    }

    tempPackage.services_fr = tempServices;

    tempServices = [];
    for (let service of json["properties"]["Services_en_list"]["formula"]["string"].split(", ")) {
      tempServices.push(service);
    }

    tempPackage.services_en = tempServices;
    console.log("Package", tempPackage);

    return tempPackage;
  }
  getPackagesFromNotion() {
    const headers = { 'content-type': 'application/json', 'Authorization': 'Bearer secret_7zOrfx8Ut1YwwCt4u06dkDYB9c2LqucGQSU2f5fZK4G', 'Notion-Version': '2022-06-28'};

    const requestJSON = {
      "filter": {
            "property": "is Active",
            "checkbox": {
              "equals": true
            }
        },
      "sorts": [
        {
          "property": "Price",
          "direction": "ascending"
        }
      ]
    };
    const body=JSON.stringify(requestJSON);

    return this.http.post(this.environmentService.getNotionUrl('getNotionPackages'),body, {headers})
      .pipe(map(res => {
          // @ts-ignore
          for (let newItem of res["results"]) {
            this.packages.push(this.jsonToPackage(newItem));
          }
        }),
        catchError((err) => {
          console.log('Error retrieving packages', err);
          return EMPTY;
        }));
  }
}
