import {Component} from '@angular/core';
import {NgIf} from "@angular/common";
import {TranslateService} from "../services/translate.service";

@Component({
  selector: 'app-legal-notice',
  standalone: true,
  imports: [
    NgIf
  ],
  templateUrl: './legal-notice.component.html',
  styleUrl: './legal-notice.component.scss'
})
export class LegalNoticeComponent {

  constructor(public translateService: TranslateService
  ) {
  }
}
