import { Component } from '@angular/core';
import {TimesheetService} from "../../../services/timesheet.service";
import {RouterLink} from "@angular/router";
import {ModalService} from "../../../services/modal.service";
import {SubscriptionSummaryComponent} from "../../subscription-summary/subscription-summary.component";
import {NgIf} from "@angular/common";
import {AuthService} from "../../../services/auth.service";
import {TranslateService} from "../../../services/translate.service";

@Component({
  selector: 'app-time-box',
  standalone: true,
  imports: [
    RouterLink,
    SubscriptionSummaryComponent,
    NgIf
  ],
  templateUrl: './time-box.component.html',
  styleUrl: './time-box.component.scss'
})
export class TimeBoxComponent {

  constructor(public authService: AuthService,
              public timesheetService: TimesheetService,
              public translateService: TranslateService,
              public modalService:ModalService) {
  }
}
