import { Component } from '@angular/core';
import {NewsService} from "../services/news.service";

@Component({
  selector: 'app-news-box',
  templateUrl: './news-box.component.html',
  styleUrls: ['./news-box.component.scss']
})
export class NewsBoxComponent {
  page: number = 1;
  newsPerPage = 7;
  constructor(public newsService: NewsService) {
  }
}
