import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, RouterLink} from "@angular/router";
import {NgForOf} from "@angular/common";
import {caseStudy} from "../../models/case-study";
import {CaseStudyService} from "../../services/case-study.service";
import {TranslateService} from "../../services/translate.service";

@Component({
  selector: 'app-case-study',
  standalone: true,
  imports: [
    RouterLink,
    NgForOf
  ],
  templateUrl: './case-study.component.html',
  styleUrl: './case-study.component.scss'
})
export class CaseStudyComponent implements OnInit{

  caseStudy: caseStudy | undefined;

  constructor(public caseStudyService: CaseStudyService,
              public translateService: TranslateService,
              private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.route.url.subscribe(urlSegments => {
      const id = urlSegments.pop()?.path; // Get the last segment of the URL
      if (id) {
        this.caseStudy = this.caseStudyService.getCaseStudyById(id);
        if (!this.caseStudy) {
          console.error('Case study not found');
        }
      }
    });
  }

  relatedCaseStudies = [
    {
      name: 'Revolutionizing Healthcare with Blockchain',
      image: 'https://picsum.photos/200/200?random=4',
      link: '/case-studies/healthcare-blockchain'
    },
    {
      name: 'Optimizing Supply Chain with IoT',
      image: 'https://picsum.photos/200/200?random=5',
      link: '/case-studies/supply-chain-iot'
    }
  ];

  currentImageIndex = 0;

  prevImage() {
    // @ts-ignore
    this.currentImageIndex = (this.currentImageIndex > 0) ? this.currentImageIndex - 1 : this.caseStudy.images.length - 1;
  }

  nextImage() {
    // @ts-ignore
    this.currentImageIndex = (this.currentImageIndex + 1) % this.caseStudy.images.length;
  }
}
