import {Component, OnInit} from '@angular/core';
import {Task} from "../../../models/task";
import {TimesheetService} from "../../../services/timesheet.service";
import {Timesheet} from "../../../models/timesheet";
import {AuthService} from "../../../services/auth.service";
import {CommonService} from "../../../services/common.service";

@Component({
  selector: 'app-off-project',
  templateUrl: './off-project.component.html',
  styleUrls: ['./off-project.component.scss']
})
export class OffProjectComponent  implements OnInit {
  title:string = 'Out of scope hours';
  timesheets:Timesheet[] = [];
  constructor(private commonService: CommonService,
              public timesheetService:TimesheetService) {
    this.timesheets = this.timesheetService.userTimesheet;
  }
  ngOnInit() {
    this.commonService.setCurrentPageTitle($localize `${this.title}`);
  }


  pageOngoing: number = 1;
  pageClosed: number = 1;
  tasksPerPage = 10;

  getTimesheets(filter:string = 'all') {
    return this.timesheetService.getTimesheets(filter);
  }
  countTimesheetHours(filter = 'all') {
    return this.timesheetService.countTimesheetHours(filter);
  }
}
