import {AfterViewInit, Component, ElementRef, ViewChild} from '@angular/core';
import {NgForOf, NgIf, SlicePipe} from "@angular/common";
import {QuoteService} from "../../services/quote.service";
import {ToastrService} from "ngx-toastr";
import {TranslateService} from "../../services/translate.service";
import {FormsModule} from "@angular/forms";

@Component({
  selector: 'app-social-picture-generator',
  standalone: true,
  imports: [
    NgIf,
    NgForOf,
    FormsModule,
    SlicePipe
  ],
  templateUrl: './social-picture-generator.component.html',
  styleUrl: './social-picture-generator.component.scss'
})
export class SocialPictureGeneratorComponent {
  constructor(
              public quoteService: QuoteService,
              public toastr : ToastrService,
              public translateService: TranslateService) {
  }
  @ViewChild('canvasElement', { static: false }) canvasElement: ElementRef<HTMLCanvasElement> | undefined;

  fonts: string[] = [
    'Arial',
    'Roboto',
    'Lato',
    'Lobster',
    'Montserrat',
    'Montez',
    'Oswald',
    'Pacifico'
  ];

  pictureTypes = [
    { name: 'LinkedIn Post', width: 1200, height: 627 },
    { name: 'LinkedIn Sponsored', width: 1200, height: 627 },
    { name: 'LinkedIn Page Life Photo', width: 900, height: 600 },
    { name: 'TikTok', width: 1080, height: 1920 },
    { name: 'Instagram Landscape', width: 1080, height: 566 },
    { name: 'Instagram Portrait', width: 1080, height: 1350 },
    { name: 'Instagram Square', width: 1080, height: 1080 },
    { name: 'Instagram Stories / Reels', width: 1080, height: 1920 },
    { name: 'Facebook Landscape', width: 1200, height: 630 },
    { name: 'Facebook Portrait', width: 630, height: 1200 },
    { name: 'Facebook Square', width: 1200, height: 1200 },
    { name: 'Facebook Stories / Reels', width: 1080, height: 1920 },
    { name: 'Youtube Thumbnail', width: 1280, height: 720 },
    // Add more picture types as needed
  ];

  selectedQuote: string = this.quoteService.getQuotes()[0].id;
  selectedFont: string = this.fonts[0];
  backgroundColor: string = '#3ECA8D';
  textColor: string = '#000000';
  generatedPicture: { width: number, height: number } | null = null;
  generatedPictureDataUrl: string | null = null;


  generatePicture(type: { name: string, width: number, height: number }) {
    this.generatedPicture = {
      width: type.width,
      height: type.height
    };

    // Adjust text color based on background color for better contrast
    this.textColor = this.getContrastingColor(this.backgroundColor);

    // Draw the picture on the canvas
    this.drawPicture();
  }


  drawPicture() {
    console.log("drawing #1");
    if (this.canvasElement && this.generatedPicture) {

      console.log("drawing #2");
      const canvas = this.canvasElement.nativeElement;
      const context = canvas.getContext('2d');
      if (context) {
        // Clear the canvas
        context.clearRect(0, 0, canvas.width, canvas.height);

        // Set the background color
        context.fillStyle = this.backgroundColor;
        context.fillRect(0, 0, canvas.width, canvas.height);

        // Set initial text settings
        context.fillStyle = this.textColor;
        let fontSize = 120;
        context.font = `${fontSize}px ${this.selectedFont}`;
        context.textAlign = 'center';
        context.textBaseline = 'middle';

        // Break text into multiple lines if necessary
        const maxWidth = canvas.width - 40; // Padding of 20px on each side
        let lines = this.getWrappedText(context, <string>this.quoteService.getQuoteById(this.selectedQuote)?.quote, maxWidth);
        let lineHeight = fontSize * 1.1;
        let totalHeight = lines.length * lineHeight;

        // Adjust font size if text exceeds canvas height
        let counting = 0;
        while ((totalHeight > canvas.height - 40) && (counting <20)) { // Padding of 20px on top and bottom
          counting ++;
          fontSize--;
          context.font = `${fontSize}px ${this.selectedFont}`;
          lines = this.getWrappedText(context, <string>this.quoteService.getQuoteById(this.selectedQuote)?.quote, maxWidth);
          totalHeight = lines.length * lineHeight;
          console.log(counting, totalHeight, canvas.height - 40);
        }

        // Draw each line of text
        const startY = (canvas.height - totalHeight) / 2;
        lines.forEach((line, index) => {
          context.fillText(line, canvas.width / 2, (lineHeight / 2) + startY + index * lineHeight);
        });

        context.fillStyle = this.textColor;
        context.font = `40px Lato`;
        context.textAlign = 'left';
        context.textBaseline = 'bottom';
        context.fillText("- " + <string>this.quoteService.getQuoteById(this.selectedQuote)?.author, 40, canvas.height - 40);

        // Update the image data URL
        this.generatedPictureDataUrl = canvas.toDataURL();

      }
    }
  }

  getWrappedText(context: CanvasRenderingContext2D, text: string, maxWidth: number): string[] {
    const words = text.split(' ');
    const lines: string[] = [];
    let currentLine = words[0];

    for (let i = 1; i < words.length; i++) {
      const word = words[i];
      const width = context.measureText(currentLine + ' ' + word).width;
      if (width < maxWidth) {
        currentLine += ' ' + word;
      } else {
        lines.push(currentLine);
        currentLine = word;
      }
    }
    lines.push(currentLine);
    return lines;
  }

  exportImage(format: 'png' | 'jpg') {
    if (this.canvasElement && this.generatedPicture) {
      const canvas = this.canvasElement.nativeElement;
      const dataUrl = canvas.toDataURL(`image/${format}`);
      const link = document.createElement('a');
      link.href = dataUrl;
      link.download = `generated-image.${format}`;
      link.click();
    }
  }

  getContrastingColor(color: string): string {
    // A simple algorithm to determine if the color is light or dark
    const hex = color.replace('#', '');
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);
    const brightness = (r * 299 + g * 587 + b * 114) / 1000;
    return brightness > 155 ? '#000000' : '#ffffff';
  }
}
