import {Component} from '@angular/core';
import {NgForOf, NgOptimizedImage} from "@angular/common";
import {ModalService} from "../../services/modal.service";
import {TranslateService} from "../../services/translate.service";
import {OfferingService} from "../../services/offering.service";

@Component({
  selector: 'app-service-list',
  standalone: true,
  imports: [
    NgOptimizedImage,
    NgForOf
  ],
  templateUrl: './service-list.component.html',
  styleUrl: './service-list.component.scss'
})
export class ServiceListComponent {
  constructor(public translateService: TranslateService,
              public modalService: ModalService,
              public offeringService: OfferingService) {
    console.log("LAnguage from service : ", this.translateService.getShortLanguage());

  }

  serviceList: any[] = [];
  serviceVisible = false;
  currentService:any;

  showService(service:any) {
    this.currentService = service;
    this.serviceVisible = true;
  }
  hideService() {
    this.serviceVisible = false;
  }
}
