import {Component, Input} from '@angular/core';
import {Project} from "../../../models/project";
import {ModalService} from "../../../services/modal.service";
import {TranslateService} from "../../../services/translate.service";

@Component({
  selector: 'app-project-box',
  templateUrl: './project-box.component.html',
  styleUrls: ['./project-box.component.scss']
})
export class ProjectBoxComponent {
  // @ts-ignore
  @Input() project:Project;

  constructor( public modalService: ModalService, public translateService: TranslateService) {
  }

  getTaskList(filter = 'all') {
    if (filter === 'all') return this.project.taskList;
    let tempList = [];
    for (let task of this.project.taskList) {
      if (task.status === filter) tempList.push(task);
    }
    return tempList;
  }

  getProgress() {
    return (this.getTaskList() !=null && this.getTaskList() !=undefined) ? Math.round(100 - ((this.getTaskList('todo').length + this.getTaskList('ongoing').length)*100 / this.getTaskList().length)) : 0;
  }

}
