<div class="audit-description">
  <span class="export-container">
    <button (click)="exportAsPDF('full-audit');" class="btn-primary" i18n>
      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 32 32"><path fill="#ffffff" d="M30 18v-2h-6v10h2v-4h3v-2h-3v-2zm-11 8h-4V16h4a3.003 3.003 0 0 1 3 3v4a3.003 3.003 0 0 1-3 3m-2-2h2a1.001 1.001 0 0 0 1-1v-4a1.001 1.001 0 0 0-1-1h-2zm-6-8H6v10h2v-3h3a2.003 2.003 0 0 0 2-2v-3a2.002 2.002 0 0 0-2-2m-3 5v-3h3l.001 3z"/><path fill="#ffffff" d="M22 14v-4a.91.91 0 0 0-.3-.7l-7-7A.909.909 0 0 0 14 2H4a2.006 2.006 0 0 0-2 2v24a2 2 0 0 0 2 2h16v-2H4V4h8v6a2.006 2.006 0 0 0 2 2h6v2Zm-8-4V4.4l5.6 5.6Z"/></svg>
      <span>Export pdf</span>
    </button>
  </span>
  <h1>{{audit?.name}}</h1>

  {{audit?.description}}
</div>

<form [formGroup]="auditFilter" class="auditFilter">
  <span class="score-filter">
    <h3 class="filter-title">Score</h3>
    <label for="minAuditLineScore">{{translateService.translate("between")}} </label>
    <input class="score-input" type="number" min="0" [max]="maxAuditLineScore.value" formControlName="minAuditLineScore" id="minAuditLineScore" #minAuditLineScore/>
    <label for="maxAuditLineScore" class="score-max-input">{{translateService.translate("and")}} </label>
    <input class="score-input" type="number" [min]="minAuditLineScore.value" max="5" formControlName="maxAuditLineScore" id="maxAuditLineScore" #maxAuditLineScore/>
  </span>
  <span class="priority-filter">
    <h3 class="filter-title">{{translateService.translate("priority")}}</h3>
    <div class="checkbox first-checkbox"><input type="checkbox" formControlName="showP1" id="showP1" ></div><label for="showP1"><strong>!!!</strong></label>
    <div class="checkbox"><input type="checkbox" formControlName="showP2" id="showP2" ></div><label for="showP2"><strong>!!</strong></label>
    <div class="checkbox"><input type="checkbox" formControlName="showP3" id="showP3" ></div><label for="showP3" class="last-checkbox-label"><strong>!</strong></label>
  </span>
  <span class="other-filter">
    <h3 class="filter-title">{{translateService.translate("display")}}</h3>
    <div class="checkbox"><input class="first-checkbox" type="checkbox" formControlName="showAuditLineDescription" id="showAuditLineDescription" ></div><label for="showAuditLineDescription" class="showAuditLineDescription">{{translateService.translate("description")}}</label>
    <div class="checkbox"><input type="checkbox" formControlName="showAuditLineAnalysis" id="showAuditLineAnalysis" ></div><label for="showAuditLineAnalysis" class="showAuditLineAnalysis">{{translateService.translate("analysis")}}</label>
    <div class="checkbox"><input type="checkbox" formControlName="showAuditLineAdvice" id="showAuditLineAdvice" ></div><label for="showAuditLineAdvice" class="showAuditLineAdvice">{{translateService.translate("advice")}}</label>
  </span>
</form>
<div id="full-audit">
<span *ngFor="let auditcategory of audit?.categories">
  <div class='audit-category-header header-score-{{(getCategoryScore(auditcategory.id)) >0 ?getCategoryScore(auditcategory.id) : "na"}}'>
    <div class='category-score score-{{(getCategoryScore(auditcategory.id)) >0 ?getCategoryScore(auditcategory.id) : "na"}}'>
      <h2>{{auditcategory.name}}</h2>
      <span class="category-score-label">{{getCategoryScore(auditcategory.id) >0 ?getCategoryScore(auditcategory.id) : "-"}}</span>
    </div>
    <div class="category-description">{{auditcategory.description}}</div>
  </div>
  <ul class="audit-lines" *ngIf="audit?.audit_lines != undefined">
    <li *ngFor="let auditLine of getAuditLinesOfCategory(auditcategory.id, true)">
      <div class="audit-line-category">/ {{auditcategory.name}}</div>
      <div class="audit-line-header">
        <span class="audit-line-name">{{auditLine.name}}</span>

        <span class="priority-tag {{auditLine.priority}}">{{auditLine.priority == "P1" ? "!!!" : auditLine.priority == "P2" ? "!!" : "!"}}</span>

        <span [class]="'score' + ' score-'+ auditLine.score">
          {{auditLine.score}}
        </span>
      </div>
      <div *ngIf="isVisibleAuditLine('Description')" class="audit-line-description">
        <span [innerHTML]="commonService.HTMLReturn(auditLine.description)"></span>
      </div>
      <div *ngIf="isVisibleAuditLine('Analysis')" class="audit-line-analysis">
        <h2>{{ translateService.translate("analysis") }}</h2>
        <span [innerHTML]="commonService.HTMLReturn(auditLine.analysis)"></span>
        <img crossorigin="*" *ngIf="auditLine.screenshotUrl && (auditLine.screenshotUrl !== '')" [src]="auditLine.screenshotUrl" alt=""/>
      </div>
      <div *ngIf="isVisibleAuditLine('Advice')" class="audit-line-todo">
        <h2>{{ translateService.translate("advice") }}</h2>
        <span [innerHTML]="commonService.HTMLReturn(auditLine.todo)"></span>
      </div>
    </li>
  </ul>
</span>
</div>
