import { Component } from '@angular/core';

@Component({
  selector: 'app-digital-maturity-box',
  templateUrl: './digital-maturity-box.component.html',
  styleUrls: ['./digital-maturity-box.component.scss']
})
export class DigitalMaturityBoxComponent {

}
