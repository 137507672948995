import {Component, Input} from '@angular/core';
import {Task} from "../../../models/task";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-tasks-box',
  templateUrl: './tasks-box.component.html',
  styleUrls: ['./tasks-box.component.scss']
})
export class TasksBoxComponent {
  @Input() taskList: Task[] =[];
  lastTaskChanged: Task = {id:'0', name: '', status:'', creationDate: new Date()};
  undoVisible = false;

  getTaskList(filter = 'all') {
    console.log("TasksBoxComponent > this.taskList", this.taskList);
    if (filter === 'all') return this.taskList;
    let tempList = [];
    for (let task of this.taskList) {
      if (task.status === filter) tempList.push(task);
    }
    return tempList;
  }


  changeTaskStatus(inputTask: Task, status: string) {
    this.lastTaskChanged = {
      id:inputTask.id,
      name:inputTask.name,
      status:inputTask.status,
      creationDate: new Date()
    };
    for (let task of this.taskList) {
      if (task.id === inputTask.id) task.status = status;
    }
    this.showUndoButton();
  }
  undoChangeTaskStatus() {
    this.changeTaskStatus(this.lastTaskChanged, this.lastTaskChanged.status);
    this.undoVisible = false;
  }
  showUndoButton() {
    let that = this;
    this.undoVisible = true;
    setTimeout(function () {
      that.undoVisible = false;
    }, 5000);
  }

}
