<div class="container">
  <div class="case-study-container">
    <div class="case-study-header">
      <h1>{{ caseStudy?.name }}</h1>
      <p class="case-study-subtitle">{{ caseStudy?.subTitle }}</p>
    </div>
    <div class="case-study-content">
      <div class="case-study-info">
        <p><strong>{{translateService.translate('Technology')}}</strong> {{ caseStudy?.technology }}</p>
        <p><strong>{{translateService.translate('Industry')}}</strong> {{ caseStudy?.industry }}</p>
        <p><strong>{{translateService.translate('Client')}}</strong> {{ caseStudy?.client }}</p>
        <p><strong>{{translateService.translate('Duration')}}</strong> {{ caseStudy?.duration }}</p>
      </div>
      <div class="case-study-summary">
        <p>{{ caseStudy?.summary }}</p>
      </div>
      <div class="case-study-images">
        <div class="slider">
          <div class="slides" [style.transform]="'translateX(-' + currentImageIndex * 100 + '%)'">
            <div class="slide" *ngFor="let image of caseStudy?.images">
              <img [src]="'/assets/img/case-studies/' + image" alt="Case study image">
            </div>
          </div>
          <button class="prev" (click)="prevImage()">&#10094;</button>
          <button class="next" (click)="nextImage()">&#10095;</button>
        </div>
      </div>
    </div>
    <div class="case-study-footer">
      <a [href]="caseStudy?.link" class="btn btn-primary">{{translateService.translate('go to')}} {{caseStudy?.link?.substr(8)}}</a>
    </div>
  </div>

  <div class="related-case-studies">
    <h3>{{ translateService.translate('other case studies') }}</h3>
    <div class="related-cases">
      <div class="related-case" *ngFor="let relatedCaseStudy of caseStudy?.relatedId">
        <a [routerLink]="'/case-study/' + relatedCaseStudy">
          <img [src]="'/assets/img/case-studies/' + caseStudyService.getCaseStudyById(relatedCaseStudy)?.imageFeatured" alt="{{caseStudyService.getCaseStudyById(relatedCaseStudy)?.name }}">
          <h4>{{ caseStudyService.getCaseStudyById(relatedCaseStudy)?.name }}</h4>
        </a>
      </div>
    </div>
  </div>
</div>
