import { Component } from '@angular/core';

@Component({
  selector: 'app-digital-maturity',
  standalone: true,
  imports: [],
  templateUrl: './digital-maturity.component.html',
  styleUrl: './digital-maturity.component.scss'
})
export class DigitalMaturityComponent {

}
