import {Component, Input} from '@angular/core';
import {ModalService} from "../../services/modal.service";
import {Project} from "../../models/project";
import {TimesheetService} from "../../services/timesheet.service";
import {Timesheet} from "../../models/timesheet";
import {CommonService} from "../../services/common.service";

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent {
  @Input() projectList:Project[] = [];
  @Input() timesheets:Timesheet[] = [];
  constructor(private modalService:ModalService,
              public commonService: CommonService,
              public timeSheetService:TimesheetService) {
  }
  openMenu() {
    this.commonService.enableLeftMenu();
  }
  closeMenu () {
    console.log("close");
    this.commonService.disableLeftMenu();
  }
  newMessage() {
    this.modalService.showModal();
  }

  countTimesheetHours(filter:string) {
    return this.timeSheetService.countTimesheetHours(filter);
  }

  getProjectTaskList(project:Project, filter = 'all') {
    if (filter === 'all') return project.taskList;
    let tempList = [];
    for (let task of project.taskList) {
      if (task.status === filter) tempList.push(task);
    }
    return tempList;
  }

  getProgress(project: Project) {
    return (this.getProjectTaskList(project) !=null && this.getProjectTaskList(project) !=undefined) ? Math.round(100 - ((this.getProjectTaskList(project,'todo').length + this.getProjectTaskList(project,'ongoing').length)*100 / this.getProjectTaskList(project).length)) : 0;
  }

}
