import {Component, OnInit} from '@angular/core';

// @ts-ignore
import AOS from "aos";
import {TranslateService} from "../../services/translate.service";

@Component({
  selector: 'app-presentation',
  standalone: true,
  imports: [],
  templateUrl: './presentation.component.html',
  styleUrl: './presentation.component.scss'
})
export class PresentationComponent implements OnInit{

  constructor(public translateService:TranslateService) {
  }
  ngOnInit() {
    AOS.init();
  }


}
