<div class="meeting-container">
  <div class="meetings" [class.sidebar]="isSidebar">
    <h2>{{translateService.translate("our-meetings")}}</h2>
    <span class="no-meeting" *ngIf="getMeetings().length == 0">{{translateService.translate("no-meeting-yet")}}</span>
    <ul>
      <li [class.activeLink]="meeting.minuteURL !== ''" (click)="showMeeting(meeting)" *ngFor="let meeting of getMeetings()| paginate: { itemsPerPage: meetingsPerPage, currentPage: page }"><span>{{meeting.meetingDate | date : 'dd/MM/YY'}}</span> {{meeting.name}}</li>
    </ul>
  </div>

  <pagination-template
    #p="paginationApi"
    (pageChange)="page = $event"
    *ngIf="getMeetings().length>meetingsPerPage">
    <div class="meeting-pagination">
      <ul>
        <li *ngFor="let page of p.pages"
            [class.active]="p.getCurrent() === page.value"
            class="pagination-item">
          <a (click)="p.setCurrent(page.value)"
             class="page-link">
          </a>
        </li>
      </ul>
    </div>
  </pagination-template>

  <div class="meeting-detail" [class.show]="meetingVisible">
    <button class="btn-close" (click)="hideMeeting()"><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="currentColor" d="m8.4 17l3.6-3.6l3.6 3.6l1.4-1.4l-3.6-3.6L17 8.4L15.6 7L12 10.6L8.4 7L7 8.4l3.6 3.6L7 15.6zm3.6 5q-2.075 0-3.9-.788t-3.175-2.137q-1.35-1.35-2.137-3.175T2 12q0-2.075.788-3.9t2.137-3.175q1.35-1.35 3.175-2.137T12 2q2.075 0 3.9.788t3.175 2.137q1.35 1.35 2.138 3.175T22 12q0 2.075-.788 3.9t-2.137 3.175q-1.35 1.35-3.175 2.138T12 22"/></svg></button>
    <span>{{currentMeeting?.meetingDate | date : 'd/M/Y'}}</span> {{currentMeeting?.name}}
    <iframe *ngIf="currentMeeting?.minuteURL !== ''" [src]="safeSrc" width="100%" height="100%"> </iframe>

  </div>
</div>
