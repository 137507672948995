import { Injectable } from '@angular/core';
import {Offering} from "../models/offering";
import {TranslateService} from "./translate.service";

@Injectable({
  providedIn: 'root'
})
export class OfferingService {
  _offerings: Offering[] =[];

  get offerings() {return this._offerings;}
  constructor(public translateService: TranslateService) {
  }
  initOffering () {
    console.log("constructing init Offering");
    this._offerings = [
      {
        id : 'digital-audit',
        title_en: "Digital Audit: Security, Accessibility, and Ethical Governance",
        title_fr: "Audit Numérique : Sécurité, Accessibilité et Gouvernance Éthique",
        img_en: 'assets/img/digital-audit-play.png',
        img_fr: 'assets/img/audit-numerique-play.png',
        description_en: "Safeguard your business in the ever-evolving digital landscape. Our comprehensive digital risk assessment provides cutting-edge guidance on online security, accessibility, and responsible data management. From compliance with cybersecurity standards to the establishment of inclusive and ethical digital strategies, get all you need to be serene.",
        description_fr: "Protégez votre entreprise dans un paysage numérique en constante évolution. Notre évaluation complète des risques numériques offre des conseils de pointe sur la sécurité en ligne, l'accessibilité et la gestion responsable des données. De la conformité aux normes de cybersécurité à l'établissement de stratégies numériques inclusives et éthiques, obtenez tout ce dont vous avez besoin pour être serein.",
        offers: [
          {
            title_en: "Digital Security Audit",
            title_fr: "Audit de Sécurité Numérique",
            description_en:  "service-1-offer-1-description",
            description_fr:  "service-1-offer-1-description-fr"
          },
          {
            title_en: "Accessibility and Inclusive Design Review",
            title_fr: "Revue de Conception Accessible et Inclusive",
            description_en:  "service-1-offer-2-description",
            description_fr:  "service-1-offer-2-description-fr"
          },
          {
            title_en: "Ethical Data Governance Assessment",
            title_fr: "Gouvernance Éthique des Données",
            description_en:  "service-1-offer-3-description",
            description_fr:  "service-1-offer-3-description-fr"
          }
        ]
      },    {
        id:'webdev',
        title_en: "Advanced and Innovative Web Development",
        title_fr: "Développement Web Avancé et Innovant",
        img_en: 'assets/img/webdev-play.png',
        img_fr: 'assets/img/dev-web-play.png',
        description_en: "Turn your ideas into innovative interactive experiences. From user interface design to backend and frontend programming, we master the latest technologies to create intuitive websites, complex applications, providing a captivating online presence.",
        description_fr: "Transformez vos idées en expériences interactives innovantes. De la conception de l'interface utilisateur à la programmation back-end et front-end, nous maîtrisons les technologies les plus récentes pour créer des sites web intuitifs et des applications complexes, offrant une présence en ligne captivante.",
        offers: [
          {
            title_en: "Intuitive Websites",
            title_fr: "Sites Web Intuitifs",
            description_en:  "service-2-offer-1-description",
            description_fr:  "service-2-offer-1-description-fr"
          },
          {
            title_en: "Custom Complex Applications",
            title_fr: "Applications Complexes Sur Mesure",
            description_en:  "service-2-offer-2-description",
            description_fr:  "service-2-offer-2-description-fr"
          },
          {
            title_en: "Captivating Online Presence",
            title_fr: "Présence En Ligne Captivante",
            description_en:  "service-2-offer-3-description",
            description_fr:  "service-2-offer-3-description-fr"
          }
        ]
      },
      {
        id : 'strategy',
        title_en: "Strategic Digital Transformation",
        title_fr: "Transformation Digitale Stratégique",
        img_en: 'assets/img/strategic-digital-transformation.png',
        img_fr: 'assets/img/strategic-digital-transformation.png',
        description_en: "We design custom digital transformation plans to align your business with emerging digital trends. From optimizing internal processes to creating innovative customer experiences, our strategic approach ensures that every step counts in your digital journey.",
        description_fr: "Nous concevons des plans de transformation digitale sur mesure pour aligner votre entreprise avec les nouvelles tendances numériques. De l'optimisation des processus internes à la création d'expériences client innovantes, notre approche stratégique garantit que chaque étape compte dans votre parcours digital.",
        offers: [
          {
            title_en: "Strategic Digital Planning",
            title_fr: "Planification Digitale Stratégique",
            description_en:  "service-3-offer-1-description",
            description_fr:  "service-3-offer-1-description-fr"
          },
          {
            title_en: "Optimization of Internal Processes",
            title_fr: "Optimisation des Processus Internes",
            description_en:  "service-3-offer-2-description",
            description_fr:  "service-3-offer-2-description-fr"
          },
          {
            title_en: "Innovative Customer Experiences",
            title_fr: "Expériences Client Innovantes",
            description_en:  "service-3-offer-3-description",
            description_fr:  "service-3-offer-3-description-fr"
          }
        ]
      }
    ];
  }
}
