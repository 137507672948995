import { Injectable } from '@angular/core';
import {environment} from "../../environments/environment.prod";

@Injectable({
  providedIn: 'root'
})
export class EnvironmentService {

  constructor() {}

  getApiRequest(apiRequest: string) {
    return environment.jwtserver + "/" + environment.apiDirectory + "/" + apiRequest + ".php";
  }
  getNotionUrl(notionRequest : string) {
    return environment.jwtserver + "/" + environment.apiDirectory + "/" + environment.apiNotionDirectory + "/" + notionRequest + ".php";
  }

}
