<div class="container">
  <h2 class="pill" i18n>Last news</h2>
  <ul>
    <li *ngFor="let newItem of newsService.news| paginate: { itemsPerPage: newsPerPage, currentPage: page }"><span class="news-date">{{newItem.creationDate | date : 'mediumDate'}}</span><a [href]="newItem.url">{{newItem.label}}</a></li>
  </ul>

<pagination-template
  #p="paginationApi"
  (pageChange)="page = $event"
  *ngIf="newsService.news.length>newsPerPage">
  <div class="news-pagination">
    <ul>
      <li *ngFor="let page of p.pages"
          [class.active]="p.getCurrent() === page.value"
          class="pagination-item">
        <a (click)="p.setCurrent(page.value)"
           class="page-link">
        </a>
      </li>

    </ul>
  </div>
</pagination-template>

</div>
