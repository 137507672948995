import { Injectable } from '@angular/core';
import {Project} from "../models/project";
import {catchError, EMPTY, map} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {TaskService} from "./task.service";
import {ResourceService} from "./resource.service";
import {TimesheetService} from "./timesheet.service";
import {MeetingService} from "./meeting.service";
import {AuditService} from "./audit.service";
import {CommonService} from "./common.service";
import {EnvironmentService} from "./environment.service";

@Injectable({
  providedIn: 'root'
})
export class ProjectService {
  userProjects: Project[] = [];
  constructor(private commonService: CommonService,
              private environmentService: EnvironmentService,
              private http: HttpClient,
              private taskService: TaskService,
              public timesheetService: TimesheetService,
              private meetingService: MeetingService,
              private resourceService: ResourceService,
              private auditService:AuditService) {

  }

  notionJsonToProject(json: any) {
    let tempProject: Project = {
      id : json.id,
      name : json["properties"]["Project name"]["title"][0]["plain_text"],
      resources : [],
      status : json["properties"]["Status"]["status"]["name"] === 'In progress' ?'ongoing':json["properties"]["Status"]["status"]["name"],
      taskList : [],
      project_type : json["properties"]["Type"]["select"]["name"],
      audit_id : json["properties"]["Type"]["select"]["name"] === 'Audit' ? json["properties"]["Audits"]["relation"][0]["id"]:''
    }

    let tempTaskList = [];
    for (let task of this.taskService.userTasks) {
      if (task.projectId == json.id) {
        tempTaskList.push(this.taskService.getTaskById(task.id));
      }
    }
    tempProject.taskList = tempTaskList;


    let tempResourceList = [];
    for (let resource of this.resourceService.userResources) {
      if (resource.projectId == json.id) {
        tempResourceList.push(this.resourceService.getResourceById(resource.id));
      }
    }
    tempProject.resources = tempResourceList;

    return tempProject;
  }
  getProjectsFromNotion(trqid = '') {
    this.commonService.enableLoader();
    const headers = { 'content-type': 'application/json', 'Authorization': 'Bearer secret_7zOrfx8Ut1YwwCt4u06dkDYB9c2LqucGQSU2f5fZK4G', 'Notion-Version': '2022-06-28'};

    const requestJSON ={
      "filter": {
        "property": "Trqid",
        "rich_text" : {
          "equals": trqid
            }
          }};

    const body=JSON.stringify(requestJSON);

    return this.http.post(this.environmentService.getNotionUrl('getNotionUser'),body, {headers})
      .pipe(map(res => {
          // @ts-ignore
          this.getNotionProjectByCustomerId(res["results"][0]["properties"]["Company"]["relation"][0]["id"]).subscribe();
          // @ts-ignore
          this.taskService.retrieveTasks(res["results"][0]["properties"]["Company"]["relation"][0]["id"]);
          // @ts-ignore
          this.resourceService.retrieveResources(res["results"][0]["properties"]["Company"]["relation"][0]["id"]);
          // @ts-ignore
          this.timesheetService.retrieveTimesheets(res["results"][0]["properties"]["Company"]["relation"][0]["id"]);
          // @ts-ignore
          this.meetingService.retrieveMeetings(res["results"][0]["properties"]["Company"]["relation"][0]["id"]);
          // @ts-ignore
          this.auditService.retrieveAudits(res["results"][0]["properties"]["Company"]["relation"][0]["id"]);
          this.commonService.disableLoader();
        }),
        catchError((err) => {
          console.log('error retrieving projects', err);
          this.commonService.disableLoader();
          return EMPTY;
        }));


  }

  getNotionProjectByCustomerId(customerId:string) {
    const headers = { 'content-type': 'application/json', 'Authorization': 'Bearer secret_7zOrfx8Ut1YwwCt4u06dkDYB9c2LqucGQSU2f5fZK4G', 'Notion-Version': '2022-06-28'};

    const requestJSON ={
      "filter": {
        "property": "Customer",
        "relation": {
          "contains": customerId
        }
      }};

    const body=JSON.stringify(requestJSON);

    return this.http.post('https://b-prod.com/tranquilys/notion/getNotionProjects.php',body, {headers})
      .pipe(map(res => {

          // @ts-ignore
          for (let project of res["results"]) {
            this.userProjects.push(this.notionJsonToProject(project));
          }

        }),
        catchError((err) => {
          console.log('Error retrieving projects',err);
          return EMPTY;
        }));

  }
  getUserProjects() {
    return this.userProjects;
  }
  getProjectById(projectId:string) {
    for (let project of this.userProjects) {
      if (project.id == projectId) { return project;}
    }
    return null;
  }

  emptyUserProjectList() {
    this.userProjects = [];
  }
}
