<div class="tasks-box">
<!-- <button (click)="undoChangeTaskStatus()" class="btn-undo" [class.show]="undoVisible">annuler</button> -->

  <h2 *ngIf="getTaskList('ongoing').length>0"><span i18n>ongoing</span> ({{getTaskList('ongoing').length}})</h2>
  <ul class="ongoing-tasks" *ngIf="getTaskList('ongoing').length>0">
    <li *ngFor="let task of getTaskList('ongoing')">{{task.name}}</li>
  </ul>
  <h2 *ngIf="getTaskList('todo').length > 0"><span i18n>to do</span> ({{getTaskList('todo').length}})</h2>
  <ul class="next-tasks" *ngIf="getTaskList('todo').length > 0">
    <li *ngFor="let todotask of getTaskList('todo')">{{todotask.name}}</li>
  </ul>
  <h2 *ngIf="getTaskList('archived').length >0"><span i18n>archived</span> ({{getTaskList('archived').length}})</h2>
  <ul class="archived-tasks" *ngIf="getTaskList('archived').length >0">
    <li *ngFor="let archivedtask of getTaskList('archived')">{{archivedtask.name}}</li>
  </ul>
  <h2 *ngIf="getTaskList('done').length > 0"><span i18n>over</span> ({{getTaskList('done').length}})</h2>
  <ul class="done-tasks" *ngIf="getTaskList('done').length > 0">
    <li *ngFor="let donetask of getTaskList('done')">{{donetask.name}}</li>
  </ul>
</div>
