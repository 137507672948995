<div class="container">
  <ul class="tabs">
    <li [class.active] = "isActive('diagnostic')" *ngIf="project?.project_type == 'Audit'" (click)="setTab('diagnostic')" i18n>Diagnostic</li>
    <li [class.active] = "isActive('tasks')" (click)="setTab('tasks')">Tasks</li>
    <li [class.active] = "isActive('resources')" (click)="setTab('resources')">Resources</li>
    <!-- <li class="project-progress"><progress [value]="getProgress()" max="100"> {{getProgress()}}% </progress></li> -->
  </ul>
  <div class="project-content">
    <app-tasks-box *ngIf="isActive('tasks')" [taskList]="getProjectTaskList()" />
    <app-resources-box *ngIf="isActive('resources')" [resources]="getProjectResources()" />
    <app-audit-box *ngIf="isActive('diagnostic')" [audit]="getProjectAudit()"  />
  </div>
</div>
