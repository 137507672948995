import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  _isVisible = false;
  _modalType = "default";
  _options :any;

  get isVisible() {return this._isVisible;}

  get modalType() {return this._modalType;}

  get options() {return this._options;}

  constructor() { }
  showModal(modalType = 'default', options? : any) {
    this._modalType = modalType;
    this._isVisible = true;
    if ((modalType === 'subscription' ||modalType === 'subscription-box'||modalType === 'meeting-minute') && options != null) {
      this._options = options;
    }
  }
  hideModal() {
    this._isVisible = false;
    this._options = [];
  }
}
