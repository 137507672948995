<div class="spinner" *ngIf="commonService.isLoading">
  <div class="loader">
    <div class="inner one"></div>
    <div class="inner two"></div>
    <div class="inner three"></div>
  </div>
</div>

<app-navigation *ngIf="authService.userIsAuthenticated" [timesheets]="timesheetService.getTimesheets()" [projectList]="projectService.getUserProjects()"/>
<span class="container">
  <app-header></app-header>
  <router-outlet></router-outlet>
</span>
<app-footer></app-footer>
<app-modal *ngIf="modalService.isVisible" />
