import { Component } from '@angular/core';
import {NgForOf, NgIf} from "@angular/common";
import {PackageService} from "../../../services/package.service";
import {DomSanitizer} from "@angular/platform-browser";
import {ModalService} from "../../../services/modal.service";
import {AuthService} from "../../../services/auth.service";
import {TranslateService} from "../../../services/translate.service";

@Component({
  selector: 'app-subscription-box',
  standalone: true,
  imports: [
    NgForOf,
    NgIf
  ],
  templateUrl: './subscription-box.component.html',
  styleUrl: './subscription-box.component.scss'
})
export class SubscriptionBoxComponent {
  showSubscription() {

    let tempOptions = {
      pack : '',
      email : '',
      firstname : '',
      lastname : '',
      phone : '',
      company : ''
    };
    tempOptions['pack'] = this.packageService.getPackages(true)[this.currentOffer].title;

    if (this.authService.userIsAuthenticated) {
      console.log('showSubscription options', this.authService.userIsAuthenticated, this.authService.currentUser);
      tempOptions['email'] = this.authService.currentUser.email;
      tempOptions['firstname'] = this.authService.currentUser.firstname;
      tempOptions['lastname'] = this.authService.currentUser.lastname;
    }

    if (this.authService.userIsAuthenticated && !this.modalService.isVisible){
      this.modalService.showModal('subscription-box', tempOptions);
    }
    else {
      this.modalService.showModal('subscription', tempOptions);
    }
  }
  constructor(public packageService: PackageService,
              public authService: AuthService,
              public modalService: ModalService,
              public translateService: TranslateService) {
  }

  currentOffer = 1;

  decreaseOffer() {
    this.currentOffer = this.currentOffer ==0 ? 0 : this.currentOffer -1;
  }
  increaseOffer() {
    this.currentOffer = this.currentOffer == this.packageService.getPackages(true).length - 1 ? this.packageService.getPackages(true).length - 1 : this.currentOffer + 1;
  }
}
