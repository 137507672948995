<h1 *ngIf="!isFiltered" i18n>All resources</h1>
<h1 *ngIf="isFiltered">
  <span *ngFor="let filterTag of filterTags">
    <button class="tag-h-btn">{{filterTag.name}}</button>
    <button class="btn-clear remove-tag-btn" (click)="removeFilter(filterTag)"></button>
  </span>
</h1>
<ul class="resources-box">
  <li *ngFor="let Resource of getFilteredResources()| paginate: { itemsPerPage: resourcesPerPage, currentPage: page }">
    <a class="resource-{{Resource.type}}" [href]="Resource.url">{{Resource.name}}</a>
    <!--<button class="edit-resource-btn" (click)="editResource(Resource)"></button>-->
    <button class="add-tag-btn" *ngFor="let tag of Resource.tags" (click)="addFilter(tag)">{{tag.name}}</button>
  </li>
</ul>
<pagination-template
  #p="paginationApi"
  (pageChange)="page = $event"
  *ngIf="getFilteredResources().length>resourcesPerPage">
  <div class="resources-pagination">
    <ul>
      <li class="pagination-nav-item">
        <a *ngIf="!p.isFirstPage()"
           [class.disabled]="p.isFirstPage()"
           (click)="p.previous()"
           class="page-link"
           aria-label="Préc.">
          <span aria-hidden="true" class="la la-caret-left"></span>
          <span class="sr-only">Préc.</span>
        </a>
        <a *ngIf="p.isFirstPage()"
           class="page-link disabled"
           aria-label="Préc.">
          <span aria-hidden="true" class="la la-caret-left"></span>
          <span class="sr-only">Préc.</span>
        </a>
      </li>
      <li *ngFor="let page of p.pages"
          [class.active]="p.getCurrent() === page.value"
          class="pagination-item">
        <a (click)="p.setCurrent(page.value)"
           class="page-link">  {{ page.label }}
        </a>
      </li>
      <li class="pagination-nav-item">
        <a *ngIf="!p.isLastPage()"
           (click)="p.next()"
           class="page-link"
           aria-label="Suiv.">
          <span aria-hidden="true" class="la la-caret-right"></span>
          <span class="sr-only">Suiv.</span>
        </a>
        <a *ngIf="p.isLastPage()"
           class="page-link disabled"
           aria-label="Suiv.">
          <span aria-hidden="true" class="la la-caret-right"></span>
          <span class="sr-only">Suiv.</span>
        </a>
      </li>

    </ul>
  </div>
</pagination-template>
