import { Component } from '@angular/core';
import {NgForOf, NgOptimizedImage} from "@angular/common";
import {ModalService} from "../../services/modal.service";
import {SubscriptionBoxComponent} from "./subscription-box/subscription-box.component";
import {TranslateService} from "../../services/translate.service";

@Component({
  selector: 'app-offers',
  standalone: true,
  imports: [
    NgOptimizedImage,
    NgForOf,
    SubscriptionBoxComponent
  ],
  templateUrl: './offers.component.html',
  styleUrl: './offers.component.scss'
})
export class OffersComponent {
  constructor(public translateService: TranslateService, public modalService:ModalService) {
  }
  askPackage () {
    this.modalService.showModal('subscription-box');
  }
  askQuote () {
    this.modalService.showModal('ask-quote');
  }

  askContact () {
    this.modalService.showModal('contact');
  }
}
