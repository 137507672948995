<div class="container">
  <div class="projects">
    <app-project-box *ngFor="let project of projectList"  [project]="project"  [routerLink]="['/secured/project']" [queryParams]="{id: project.id}"/>
    <app-project-box *ngIf="projectList.length == 0" [project]="dummyProject" />
    <app-project-box [project]="emptyProject" />
  </div>
  <div class="complementary-info">
    <app-meeting-list />
    <app-time-box />
    <app-profile-box />
  </div>
</div>
