import { Injectable } from '@angular/core';
import {caseStudy} from "../models/case-study";
import {TranslateService} from "./translate.service";

@Injectable({
  providedIn: 'root'
})
export class CaseStudyService {
  constructor(private translateService: TranslateService) {
  }
  caseStudiesFr: caseStudy[] = [
    {
      id: 'tukoa',
      name: 'Accompagnement en Stratégie Numérique pour une Créatrice de Bijoux',
      subTitle: 'Dans le cadre de l\'évolution de son entreprise, Tukoa souhaitait optimiser son utilisation du numérique. Cette entreprise viennoise de création de bijoux a naturellement souhaité être conseillée régulièrement pour améliorer ses processus internes de gestion, son fonctionnement, ses employés et ses contenus.',
      technology: 'Google Analytics, Looker Studio, Mailchimp, Wix, Google Sheets',
      industry: 'Haute Joaillerie',
      client: 'Tukoa',
      duration: 'accompagnement régulier',
      summary: 'Notre accompagnement se déroule à travers des appels bi-mensuels où nous traitons les sujets en cours. Chaque appel se concentre sur un à deux sujets prioritaires dans des domaines variés. D\'une session à l\'autre, nous nous fixons des objectifs clairs à atteindre, que ce soit par Tukoa ou en collaboration avec Tranquilys pour les aspects plus techniques.\n' +
        '\n' +
        'Depuis le début de notre collaboration, de nombreux projets ont vu le jour : stratégie de newsletters, création d\'un tableau de bord analytique simplifié grâce à Looker Studio, analyse concurrentielle, développement d\'un outil de gestion des congés, amélioration de l\'ergonomie du site web, et stratégie SEO, entre autres.',
      imageFeatured: 'feature-tukoa.jpg',
      images: [
        'tukoa-1.jpg',
        'tukoa-2.jpg',
        'tukoa-3.jpg'
      ],
      link: 'https://tukoa.com',
      relatedId:['pep','noremat']
    },
    {
      id: 'noremat',
      name: 'Migration de Données de Gestion de Stocks chez NOREMAT',
      subTitle: 'Dans le cadre de la refonte de son outil de gestion des stocks, NOREMAT avait besoin de restructurer les données de son ancien système pour les rendre compatibles avec le nouveau. Ce projet complexe impliquait la compréhension de plus de 100 000 produits et pièces détachées, ainsi que l\'importation des données spécifiques dans une nouvelle structure pour assurer une interprétation correcte par le nouvel outil.',
      technology: 'PHP, CSV, SQL',
      industry: 'Équipements et services pour l\'environnement et l\'aménagement extérieur.',
      client: 'Noremat',
      duration: '3 mois',
      summary: 'La migration des données de gestion de stocks pour NOREMAT a été réalisée à travers la création de scripts PHP dédiés à la génération et à l\'import/export de fichiers CSV depuis une base SQL. Ce processus a permis d\'assurer une transition efficace et sécurisée vers le nouveau système sur une période de 3 mois.',
      imageFeatured: 'feature-noremat.jpg',
      images: [
        'noremat-1.jpg',
        'noremat-2.jpg'
      ],
      link: 'https://noremat.fr',
      relatedId:['tukoa','savills']
    },
    {
      id: 'pep',
      name: 'Optimisation numérique des contrats : Étude de cas avec PEP Lor\'Est',
      subTitle: 'Les associations du réseau PEP interviennent dans les domaines de la petite enfance, de l’éducation, des loisirs, du social, du médico-social et du sanitaire, couvrant l’ensemble des départements français. Dans le cadre de l\'évolution d\'un outil de gestion des contrats, PEP Lor\'Est a sollicité un diagnostic d\'évaluation numérique.',
      technology: 'Diagnostic, Notion.so',
      industry: 'Services sociaux et médico-sociaux',
      client: 'Pep Lor\'Est',
      duration: '4 semaines',
      summary: 'Cet audit a été réalisé dans le but d\'évaluer la sécurité, la performance et la gestion des données de l\'outil, en mettant l\'accent sur les aspects numériques cruciaux. La sécurité des données, la performance du système et la gestion des informations sensibles sont des éléments clés impactants. L\'audit a suivi une méthodologie rigoureuse incluant l\'analyse détaillée de multiples facettes de l\'outil, accompagnée de recommandations spécifiques pour renforcer la robustesse et l\'efficacité de l’environnement numérique.',
      imageFeatured: 'feature-pep.jpg',
      images: [
        'pep-1.jpg',
        'pep-2.jpg',
        'pep-3.jpg'
      ],
      link: 'https://peplorest.org',
      relatedId:['savills','tukoa']
    },
    {
      id: 'savills',
      name: 'Simulateur de configuration d\'espaces de bureaux',
      subTitle: 'Dans le cadre du développement de ses prestations de conseil en achat/location d\'espace de bureau, Savills a souhaité créer un outil permettant de mieux conseiller ses clients en tenant compte de critères permettant d\'évaluer l\'espace de bureau nécessaire : nombre d\'employés, répartition du type d\'activité, taux de télétravail, ...',
      technology: 'HTML5, CSS3, JS, PHP, FPDF, Figma',
      industry: 'Immobilier',
      client: 'SAVILLS',
      duration: '5 mois',
      summary: 'À partir d\'une première maquette Figma, la partie frontend du simulateur a été développée (HTML5, CSS3, JS) et adaptée pour être parfaitement responsive. Après une première étape de validation, le processus de réception des demandes en interne a été défini pour laisser la place au développement du backend (PHP, CSV). Les demandes effectuées par les clients sont envoyées directement aux personnes concernées. Chaque conseiller reçoit lui-même un document PDF généré lors de la simulation; document qui servira de base d\'échange entre le conseiller et le client. Par ailleurs, chaque demande est stockée en base de données ainsi que dans des fichiers conformes aux standards internes.',
      imageFeatured: 'feature-savills.jpg',
      images: [
        'savills-1.jpg',
        'savills-2.jpg',
        'savills-3.jpg'
      ],
      link: 'https://workspacecalculator.savills.fr',
      relatedId:['noremat','pep']
    }
  ];

  caseStudiesEn: caseStudy[] = [
    {
      id: 'tukoa',
      name: 'Digital Strategy Support for a Jewelry Designer',
      subTitle: 'As part of the evolution of her business, Tukoa wanted to optimize her use of digital tools. This Vienna-based jewelry creation company naturally sought regular advice to improve her internal management processes, operations, employee management, and content.',
      technology: 'Google Analytics, Looker Studio, Mailchimp, Wix, Google Sheets',
      industry: 'High Jewelry',
      client: 'Tukoa',
      duration: 'ongoing support',
      summary: 'Our support is provided through bi-monthly calls where we address ongoing topics. Each call focuses on one to two priority subjects across various domains. From one session to the next, we set clear goals to achieve, either by Tukoa or in collaboration with Tranquilys for more technical aspects.\n' +
        '\n' +
        'Since the start of our collaboration, numerous projects have been successfully completed: newsletter strategy, creation of a simplified analytical dashboard using Looker Studio, competitive analysis, development of a leave management tool, website ergonomics optimization, and SEO strategy, among others.',
      imageFeatured: 'feature-tukoa.jpg',
      images: [
        'tukoa-1.jpg',
        'tukoa-2.jpg',
        'tukoa-3.jpg'
      ],
      link: 'https://tukoa.com',
      relatedId:['pep','noremat']
    },
    {
      id: 'noremat',
      name: 'Stock Management Data Migration at NOREMAT',
      subTitle: 'As part of the redesign of its stock management tool, NOREMAT needed to restructure data from its old system to make it compatible with the new one. This complex project involved understanding over 100,000 products and spare parts, and importing specific data into a new structure to ensure accurate interpretation by the new tool.',
      technology: 'PHP, CSV, SQL',
      industry: 'Equipment and services for environmental and outdoor landscaping.',
      client: 'Noremat',
      duration: '3 months',
      summary: 'The stock management data migration for NOREMAT was conducted through the development of PHP scripts for CSV file generation and SQL database import/export. This approach ensured a seamless and secure transition to the new system over a 3-month period.',
      imageFeatured: 'feature-noremat.jpg',
      images: [
        'noremat-1.jpg',
        'noremat-2.jpg'
      ],
      link: 'https://noremat.fr',
      relatedId:['tukoa','savills']
    },
    {
      id: 'pep',
      name: 'Digital Contract Management Optimization: Case Study with PEP Lor\'Est',
      subTitle: 'The associations within the PEP network operate in areas including early childhood, education, leisure, social welfare, medico-social services, and healthcare, spanning all French departments. As part of enhancing a contract management tool, PEP Lor\'Est requested a digital evaluation diagnostic.',
      technology: 'Diagnostic, Notion.so',
      industry: 'Social and healthcare services',
      client: 'Pep Lor\'Est',
      duration: '4 weeks',
      summary: 'This audit was conducted to assess the security, performance, and data management of the tool, focusing on critical digital aspects. Data security, system performance, and sensitive information management are key impactful elements. The audit followed a rigorous methodology involving detailed analysis of multiple facets of the tool, along with specific recommendations to enhance the robustness and effectiveness of the digital environment.',
      imageFeatured: 'feature-pep.jpg',
      images: [
        'pep-1.jpg',
        'pep-2.jpg',
        'pep-3.jpg'
      ],
      link: 'https://peplorest.org',
      relatedId:['savills','tukoa']
    },
    {
      id: 'savills',
      name: 'Office Space Configuration Simulator',
      subTitle: 'As part of the development of its advisory services for office space purchasing and leasing, Savills aimed to create a tool to better advise its clients by taking into account criteria for evaluating the necessary office space: number of employees, distribution of activity types, remote work rate, etc.',
      technology: 'HTML5, CSS3, JS, PHP, FPDF, Figma',
      industry: 'Real Estate',
      client: 'SAVILLS',
      duration: '5 months',
      summary: 'Starting from an initial Figma prototype, the frontend of the simulator was developed (HTML5, CSS3, JS) and adapted to be fully responsive. After an initial validation phase, the internal request reception process was defined, paving the way for backend development (PHP, CSV). Client requests are sent directly to the concerned parties. Each advisor receives a PDF document generated during the simulation, which will serve as a basis for communication between the advisor and the client. Additionally, each request is stored in a database as well as in files compliant with internal standards.',
      imageFeatured: 'feature-savills.jpg',
      images: [
        'savills-1.jpg',
        'savills-2.jpg',
        'savills-3.jpg'
      ],
      link: 'https://workspacecalculator.savills.fr',
      relatedId:['noremat','pep']
    }
  ];
  getCaseStudyById(id: string): caseStudy | undefined {
    switch (this.translateService.getShortLanguage()) {
      case 'fr' : return this.caseStudiesFr.find(caseStudy => caseStudy.id === id);
      default :return this.caseStudiesEn.find(caseStudy => caseStudy.id === id);
    }
  }
}
