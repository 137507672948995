import {Component, OnInit} from '@angular/core';
import {AuthService} from "../services/auth.service";
import {ActivatedRoute} from "@angular/router";
import {CommonService} from "../services/common.service";
import {TranslateService} from "../services/translate.service";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent{
  constructor(public authService: AuthService,
              public commonService: CommonService,
              public translateService: TranslateService) {
  }

}
