import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Subscription} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class TranslateService {
  languageSubscription : Subscription | undefined;
  translations = [];
  _currentLanguage = "";
  constructor(private http: HttpClient) {
  }

  get currentLanguage() {return this._currentLanguage;}
  setLanguage(lang = 'en-US') {
    this._currentLanguage = (lang == "en-US" || lang == "fr-FR") ? lang : "en-US";
  }
  getShortLanguage() {
    return this._currentLanguage.substring(0,2);
  }
  loadLanguage(lang = "en-US") {
    this._currentLanguage = (lang == "en-US" || lang == "fr-FR") ? lang : "en-US";
    console.log("constructing Language");
    this.languageSubscription?.unsubscribe();
    this.languageSubscription = this.http.get("../../assets/locale/" + this._currentLanguage + ".json").subscribe(data => {
      this.translations = JSON.parse(JSON.stringify(data));
    })
  }

  translate(source:string) {
    // @ts-ignore
    return this.translations[source] != undefined ? this.translations[source] : source;
  }

}
