import {Component, HostListener, ElementRef, Input, OnInit} from '@angular/core';
import {CommonService} from "../../services/common.service";
import {PasswordService} from "../../services/password.service";
import {NgIf} from "@angular/common";

@Component({
  selector: 'app-privacy',
  standalone: true,
  imports: [
    NgIf
  ],
  templateUrl: './privacy.component.html',
  styleUrl: './privacy.component.scss'
})
export class PrivacyComponent implements OnInit {
  title:string = 'Manage your privacy';
  pwdCheck= false;
  constructor(private commonService: CommonService,
              public passwordService:PasswordService,
              private eRef: ElementRef) {
  }
  ngOnInit() {
    this.commonService.setCurrentPageTitle($localize `${this.title}`);
  }

  @HostListener('document:click', ['$event'])
  clickout(event:Event) {
    //clicked outside
    if(!this.eRef.nativeElement.contains(event.target)) {
      console.log("clickedout");
      this.disablePwdCheck();
    }
  }
  onSubmit(pwd1:string, pwd2:string) {
    if (this.passwordService.checkPasswords(pwd1, pwd2))
      this.passwordService.changePassword(pwd1);
  }
  displayPwdCheck() {
    return this.pwdCheck;
  }
  enablePwdCheck() {
    this.pwdCheck = true;
  }
  disablePwdCheck() {
    this.pwdCheck = false;
  }
    protected readonly console = console;
}
