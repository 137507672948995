import { Injectable } from '@angular/core';
import {catchError, EMPTY, map, Subscription} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {Meeting} from "../models/meeting";
import {EnvironmentService} from "./environment.service";

@Injectable({
  providedIn: 'root'
})
export class MeetingService {
  userMeetings: Meeting[] =[];
  // @ts-ignore
  getMeetingsSubscription:Subscription;

  constructor(private environmentService:EnvironmentService, private http: HttpClient) {
  }

  notionJsonToMeeting(json:any) {
    let tempMinuteURL = json["properties"]["Minutes"]["files"][0] ?json["properties"]["Minutes"]["files"][0]["file"]["url"] : '';
    let tempTimesheet: Meeting = {
      id: json.id,
      name: json["properties"]["Name"]["title"][0]["plain_text"],
      type: this.getMeetingType(json["properties"]["Type"]["select"]["name"]), // topay, canceled, nonbillable, paid
      meetingDate: json["properties"]["Event time"]["date"]["start"],
      minuteURL:tempMinuteURL
    }
    return tempTimesheet;
  }

  retrieveMeetings(customerId:string) {
    this.getMeetingsSubscription = this.getMeetingFromNotion(customerId).subscribe();
  }

  getMeetingFromNotion(customerId:string = "test") {
    const headers = { 'content-type': 'application/json', 'Authorization': 'Bearer secret_7zOrfx8Ut1YwwCt4u06dkDYB9c2LqucGQSU2f5fZK4G', 'Notion-Version': '2022-06-28'};

    const requestJSON ={
      "filter": {
        "property": "Customer",
        "relation": {
          "contains": customerId
        }
      }};

    const body=JSON.stringify(requestJSON);

    return this.http.post(this.environmentService.getNotionUrl('getNotionMeetings'),body, {headers})
      .pipe(map(res => {
          // @ts-ignore
          for (let meeting of res["results"]) {
            this.userMeetings.push(this.notionJsonToMeeting(meeting));
          }

        }),
        catchError((err) => {
          console.log('Error retrieving timesheets', err);
          return EMPTY;
        }));

  }


  getMeetingType(meetingType: string) {
    let tempMeetingType:string;
    switch(meetingType) {
      case 'To Pay' :
        tempMeetingType = 'topay';
        break;
      case 'Non-Billable':
        tempMeetingType = 'nonbillable';
        break;
      default : return meetingType.toLowerCase();
    }
    return tempMeetingType.toLowerCase();
  }

  getMeetings(filter:string = 'all') {
    if (filter != 'all') {
      let tempMeetings = [];
      for (let meeting of this.userMeetings) {
        if (meeting.type == filter) tempMeetings.push(meeting)
      }
      return tempMeetings;
    }
    return this.userMeetings;
  }


  emptyMeetings() {
    this.userMeetings = [];
    this.getMeetingsSubscription.unsubscribe();
  }
}
