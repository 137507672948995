export const environment = {
  production: false,
  jwtserver : '',
  jwtport:'',
  jwtLoginUrl:'',
  jwtRegisterUrl:'',
  atTokenId: '',
  atToken: '',
  apiDirectory : '',
  apiNotionDirectory : '',
  gaEvt: {
    sign_up : {
      name : "sign_up",
      details: "",
      category: "custom"
    },
    ad_impression : {
      name : "ad_impression",
      details: "",
      category: "custom"
    },
    generate_lead : {
      name : "generate_lead",
      details: "",
      category: "custom"
    },
    login : {
      name : "login",
      details: "",
      category: "custom"
    },
    logout : {
      name : "logout",
      details: "",
      category: "custom"
    },
    purchase : {
      name : "purchase",
      details: "",
      category: "custom"
    },
    select_content : {
      name : "select_content",
      details: "",
      category: "custom"
    }
  }
};
