import {Component, Input, OnInit} from '@angular/core';
import {Audit, AuditCategory, AuditLine} from "../../../models/audit";
import {NgForOf, NgIf} from "@angular/common";
import {CommonService} from "../../../services/common.service";
import {FormBuilder, FormGroup, ReactiveFormsModule} from "@angular/forms";
import * as jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import jsPDF from "jspdf";
import {AuthService} from "../../../services/auth.service";
import {ToastrService} from "ngx-toastr";
import {TranslateService} from "../../../services/translate.service";
@Component({
  selector: 'app-audit-box',
  standalone: true,
  imports: [
    NgIf,
    NgForOf,
    ReactiveFormsModule
  ],
  templateUrl: './audit-box.component.html',
  styleUrl: './audit-box.component.scss'
})
export class AuditBoxComponent implements OnInit{
  @Input() audit: Audit|undefined;

  // @ts-ignore
  auditFilter: FormGroup;

  constructor(public authService: AuthService,
              public commonService: CommonService,
              public formBuilder: FormBuilder,
              public translateService: TranslateService,
              public toastrService: ToastrService) {

  }
  ngOnInit() {
    this.auditFilter = this.formBuilder.group({
      minAuditLineScore : [0],
      maxAuditLineScore : [5],
      showP1 : [true],
      showP2 : [true],
      showP3 : [true],
      showAuditLineDescription : [true],
      showAuditLineAdvice : [true],
      showAuditLineAnalysis : [true]
    });
  }

  getCategoryScore(auditCategoryId:string) {
    if (this.audit) {
      let totalScore = 0;
      let dividerScore = 0;
      for (let auditLine of this.audit?.audit_lines) {
        if (auditLine.category_id == auditCategoryId) {
         totalScore += (auditLine.score * this.getPriorityCoefficient(auditLine.priority));
         dividerScore += this.getPriorityCoefficient(auditLine.priority);
        }
      }
      if (dividerScore >0)
        return Math.round(totalScore / dividerScore);
      else
        return -1;
    }
    return -1;
  }

  getPriorityCoefficient(priorityId:string) {
    switch (priorityId) {
      case "P1": return 3;
      case "P2" : return 2;
      case "P1" :
      default : return 1;
    }
  }

  getAuditLinesOfCategory(auditCategoryId:string, filterAuditLines = false) {
    let auditLines = [];
    if (this.audit) {
      for (let auditLine of this.audit?.audit_lines) {
        if (auditLine.category_id == auditCategoryId)
          if (filterAuditLines)
          {
            if (this.isCheckedPriority(auditLine.priority)
              && (auditLine.score >= this.auditFilter?.value.minAuditLineScore)
              && (auditLine.score <= this.auditFilter?.value.maxAuditLineScore)
            ) {
              auditLines.push(auditLine);
            }
          }
        else {
            auditLines.push(auditLine);
          }
      }
    }
    return auditLines;
  }

  isCheckedPriority(priority:string) {
    switch (priority) {
      case "P1" : return this.auditFilter?.value.showP1;
      case "P2" : return this.auditFilter?.value.showP2;
      case "P3" : return this.auditFilter?.value.showP3;
      default: return true
    }
  }

  isVisibleAuditLine(AuditContent = "Description") {
    switch (AuditContent) {
      case "Description" : return this.auditFilter?.value.showAuditLineDescription;
      case "Analysis" : return this.auditFilter?.value.showAuditLineAnalysis;
      case "Advice" : return this.auditFilter?.value.showAuditLineAdvice;
      default: return true;
    }
  }
  exportAsPDF(divId:any)
  {
    this.toastrService.info($localize `Generation started, please be patient :)`);
    let data = document.getElementById(divId);


    // @ts-ignore
    html2canvas(data, {useCORS: true}).then(canvas => {

      let imgWidth = 200;
      let pageHeight = 285;
      let imgHeight = canvas.height * imgWidth / canvas.width;
      let heightLeft = imgHeight;

      let doc = new jsPDF('p', 'mm');
      let position = 5;

      let bgPDFHome = new Image();
      bgPDFHome.src = 'assets/img/background-pdf-report.jpg';
      doc.addImage(bgPDFHome, 'JPG', 0, 0, 210, 140);

      doc.setFontSize(40);
      doc.setTextColor("#FFFFFF");
      // @ts-ignore
      doc.text(this.audit?.name, 105, 80, 'center');

      doc.setFontSize(16);
      // @ts-ignore
      doc.text(this.translateService.translate("diag-elaborated-for")+ ' ' + this.audit?.company, 105, 88, 'center');

      doc.setFontSize(10);
      doc.setTextColor("#FFFFFF");
      // @ts-ignore
      doc.text(this.translateService.translate('diag-generated-by') + ' ' + this.authService.currentUser.firstname + ' ' + this.authService.currentUser.lastname + ' ' + this.translateService.translate('diag-on') +' ' + new Date().toLocaleDateString(), 208, 136, 'right');


      doc.setFontSize(12);
      doc.setTextColor("#000000");
      // @ts-ignore
      let splitDescription = doc.splitTextToSize(this.audit?.description, 160);
      doc.text(splitDescription, 24, 194);


      let pageNum = 1;
      // @ts-ignore
      for (let auditCategory of this.audit?.categories) {
        this.displayCategory(doc, auditCategory, pageNum);
        pageNum ++;
        for (let auditLine of this.getAuditLinesOfCategory(auditCategory.id, false)) {
          if (auditLine.priority == 'P1') {
            this.displayAuditLine(doc, auditLine,auditCategory, pageNum);
            pageNum ++;
          }
        }
        for (let auditLine of this.getAuditLinesOfCategory(auditCategory.id, false)) {
          if (auditLine.priority == 'P2') {
            this.displayAuditLine(doc, auditLine,auditCategory, pageNum);
            pageNum ++;
          }
        }
        for (let auditLine of this.getAuditLinesOfCategory(auditCategory.id, false)) {
          if (auditLine.priority == 'P3') {
            this.displayAuditLine(doc, auditLine,auditCategory, pageNum);
            pageNum ++;
          }
        }
      }


      /* Those lines retrieve the HTML Data, if necessary
      const contentDataURL = canvas.toDataURL('image/jpeg')  // 'image/jpeg' for lower quality output.

      doc.addImage(contentDataURL, 'jpeg', 5, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
      while (heightLeft >= 5) {
        position = heightLeft - imgHeight;
        this.displayFooter(doc,pageNum);
        pageNum ++;
        doc.addPage();
        doc.addImage(contentDataURL, 'jpeg', 5, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }*/

      /* Last page */
      doc.addPage();
      let bgPDFLast = new Image();
      if (!this.audit?.preview) {
        bgPDFLast.src = 'assets/img/background-last-page.jpg';
        doc.addImage(bgPDFLast, 'JPG', 0, 157, 210, 140);


        doc.setTextColor(5, 165, 150);
        doc.setFontSize(26);
        doc.text("CONCLUSION", 25, 30);

        doc.setFontSize(12);
        doc.setTextColor("#000000");
        // @ts-ignore
        let splitConclusion = doc.splitTextToSize(this.audit?.conclusion, 160);
        doc.text(splitConclusion, 25, 50);
      }
      else {
        bgPDFLast.src = 'assets/img/audit-cta-fr.jpg';
        doc.addImage(bgPDFLast, 'JPG', 0, 0, 210, 297);
      }




      doc.save( 'diagnostic.pdf');

      this.toastrService.info($localize `Your report is ready. Check your downloads !`);


    });
  }
  displayFooter(doc:any, pageNumber:number, pageFormat = "A4", orientation = "portrait"){
    doc.setFontSize(12);
    doc.setTextColor("#025A59");

    switch (pageFormat) {
      case "A4" :
      default   :
        // @ts-ignore
        doc.text(new Date().toLocaleDateString() + " | " + this.audit?.name, 10, 288);
        doc.text(pageNumber.toString(), 200, 288);
    }
  }
  displayCategory(doc:any, auditCategory: AuditCategory, pageNumber:number,pageFormat = "A4", orientation = "portrait") {
    doc.addPage();

    switch (pageFormat) {
      case "A4" :
      default   :
        doc.addImage(auditCategory.imageURL, 'jpeg', 0, 0, 210, 140);

        doc.setFillColor(5, 165, 150);
        doc.rect(12, 126, 140, 28,'F');

        doc.setFontSize(34);
        doc.setTextColor("#FFFFFF");

        doc.text(auditCategory.name, 20, 144);

        doc.setFontSize(16);
        doc.setTextColor("#025A59");
        // @ts-ignore
        let splitDescription = doc.splitTextToSize(auditCategory.description, 160);
        doc.text(splitDescription, 24, 174);

        this.displayScore(doc, this.getCategoryScore(auditCategory.id), 70, 234);
        this.displayFooter(doc,pageNumber,pageFormat,orientation);
    }
  }

  displayScore(doc: any, score: number, xpos = 0, ypos = 0) {

    if (score >=0)
    {
      doc.setFontSize(22);
      doc.setTextColor("#000000");
      doc.text(this.translateService.translate('diag-how-you-perform'), 105, ypos,'center');
    }
    else {
      doc.setFontSize(14);
      doc.setTextColor("#999999");
      doc.text(this.translateService.translate('diag-no-evaluation'), 105, ypos,'center');
    }

    doc.setFontSize(22);
    let bgSCore = new Image()
    bgSCore.src = 'assets/img/background-score.jpg';
    doc.addImage(bgSCore, 'jpg', xpos, ypos + 4, 70, 40);
    doc.setFontSize(34);
    doc.setTextColor("#000000");
    doc.text(score >=0 ? score.toString() : "", xpos + 30, ypos + 38);

    if (score>=0)
    {
      let xpos1 = xpos + 35;
      let ypos1 = ypos + 36;
      let xpos2 = xpos + 6;
      let ypos2 = ypos + 34;
      let xpos3 = xpos + 6;
      let ypos3 = ypos + 36;
      switch (score) {
        case 5 :  xpos1 = xpos + 38;
          xpos2 = xpos + 62;
          xpos3 = xpos + 62;
          break;
        case 4: xpos3 = xpos + 56.5;
          ypos3 = ypos + 20.5;
          xpos2 = xpos + 57.5;
          ypos2 = ypos + 22;
          xpos1 = xpos + 38;
          ypos1 = ypos + 32;
          break;
        case 3:xpos3 = xpos + 47;
          ypos3 = ypos + 11.5;
          xpos2 = xpos + 48.5;
          ypos2 = ypos + 12.5;

          xpos1 = xpos + 37;
          ypos1 = ypos + 30;
          break;
        case 2: xpos3 = xpos + 21;
          ypos3 = ypos + 11.5;
          xpos2 = xpos + 19.5;
          ypos2 = ypos + 12.5;
          xpos1 = xpos + 30;
          ypos1 = ypos + 29;
          break;
        case 1:
          xpos3 = xpos + 11.5;
          ypos3 = ypos + 20;
          xpos2 = xpos + 10;
          ypos2 = ypos + 22;
          xpos1 = xpos + 30;
          ypos1 = ypos + 32;
          break;
        case 0: xpos1 = xpos + 30;break;
        default:
          break;
      }
      doc.triangle(xpos1,ypos1,xpos2,ypos2,xpos3,ypos3,'F');
    }
  }
  displayAuditLineScore(doc: any, score: number, xpos = 0, ypos = 0) {
    let scoreColor = "";
    let scoreColorText = "";

    switch (score) {
      case 5:scoreColor = "#C6E0BF";scoreColorText = "#63705f";break;
      case 4:scoreColor = "#7BBEE8";scoreColorText = "#3d5f74";break;
      case 3:scoreColor = "#F2EDAF";scoreColorText = "#797657";break;
      case 2:scoreColor = "#F2CF92";scoreColorText = "#917c57";break;
      case 1:
      case 0:
      default:scoreColor = "#E87D75";scoreColorText = "#743e3a";break;
    }
    doc.setFillColor(scoreColor);
    doc.rect(xpos, ypos, 18,18,'F');

    doc.setFontSize(10);
    doc.setTextColor(scoreColorText);
    doc.text('score', xpos + 9, ypos + 5,'center');
    doc.setFontSize(18);
    doc.text(score + '/5', xpos + 9, ypos + 13,'center');
  }

  displayAuditLinePriority(doc: any, priority: string, xpos = 0, ypos = 0) {


    //let priorityColor = priority == "P1" ? "#679c9b" : priority == "P2" ? "#347a7a" : "#025a59";
    let priorityColor = "#222222";
    doc.setFillColor(priorityColor);
    doc.rect(xpos, ypos, 18,18,'F');

    doc.setFontSize(10);
    doc.setTextColor('#FFFFFF');

    let priorityText = priority == "P1" ? "!!!" : priority == "P2" ? "!!" : "!";

    doc.setFontSize(10);
    doc.text('priority', xpos + 9, ypos + 5,'center');
    doc.setFontSize(18);
    doc.text(priorityText, xpos + 9, ypos + 13,'center');
  }
  displayAuditLine(doc:any, auditLine: AuditLine, auditCategory: AuditCategory, pageNumber:number,pageFormat = "A4", orientation = "portrait") {
    doc.addPage();
    switch (pageFormat) {
      case "A4" :
      default   :

        doc.setFontSize(42);
        doc.setTextColor("#025A59");
        doc.text("/", 13, 22);


        doc.setFontSize(15);
        doc.text(auditCategory.name, 19, 20);

        doc.setFillColor("#025A59");
        doc.rect(12, 25, 150, 18,'F');
        doc.setFontSize(18);
        doc.setTextColor("#FFFFFF");
        doc.text(auditLine.name, 22, 36);

        doc.setFontSize(12);
        doc.setTextColor("#025A59");
        // @ts-ignore
        let splitDescription = doc.splitTextToSize(auditLine.description, 170);
        doc.text(splitDescription, 20, 56, {lineHeightFactor: 1.5, align : 'left', maxWidth :170});

        this.displayAuditLineScore(doc,auditLine.score,163,25);
        this.displayAuditLinePriority(doc,auditLine.priority,182,25);

        doc.setTextColor(5, 165, 150);
        doc.setFontSize(26);
        doc.text(this.translateService.translate("diag-analysis").toUpperCase(), 24, 110);
        doc.setFillColor(5, 165, 150);
        doc.rect(12, 98 , 3, 44,'F');

        doc.setTextColor("#000000");
        doc.setFontSize(12);
        let splitAnalysis = doc.splitTextToSize(auditLine.analysis, 162);
        doc.text(splitAnalysis, 24, 118, {lineHeightFactor: 1.5, align : 'left', maxWidth :162});


        doc.addImage(auditLine.screenshotUrl, 'jpeg', 15, 160, 120, 120);


        doc.setTextColor("FFFFFF");

        let todoWidth = 170;
        let todoHeight = 60;
        let todoXpos = 0;
        let todoYpos = 180;
        let titleSize = 52;
        let titleYpos = 14;
        if (auditLine.screenshotUrl != "") {
          todoWidth = 100;
          todoHeight = 82;
          todoXpos = 110;
          todoYpos = 215;
          titleSize = 32;
          titleYpos = 10;
        }

        doc.setFillColor("#4BF2AA");
        doc.rect(todoXpos, todoYpos , todoWidth, todoHeight,'F');


        doc.setTextColor("#A5F9D4");
        doc.setFontSize(titleSize);
        doc.text(this.translateService.translate("diag-next-steps"), todoXpos + 1, todoYpos + titleYpos);

        doc.setTextColor("#195139");
        doc.setFontSize(14);
        let splitTodo = doc.splitTextToSize(auditLine.todo, todoWidth - 20);
        doc.text(splitTodo, todoXpos + 10, todoYpos + 14, {lineHeightFactor: 1.5, align : 'left', maxWidth :todoWidth - 20});


        this.displayFooter(doc,pageNumber,pageFormat,orientation);
    }
  }
}
