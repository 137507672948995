import { Component } from '@angular/core';

@Component({
  selector: 'app-press-kit',
  standalone: true,
  imports: [],
  templateUrl: './press-kit.component.html',
  styleUrl: './press-kit.component.scss'
})
export class PressKitComponent {

}
