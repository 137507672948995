import {Component, OnInit} from '@angular/core';
import {CommonService} from "../../services/common.service";
import {AuthService} from "../../services/auth.service";
import {ModalService} from "../../services/modal.service";
import {NgIf} from "@angular/common";

@Component({
  selector: 'app-profile',
  standalone: true,
  imports: [
    NgIf
  ],
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.scss'
})
export class ProfileComponent implements OnInit{
  title:string = 'Edit your profile';
  constructor(private commonService: CommonService, public authService: AuthService, public modalService: ModalService) {
  }
  ngOnInit() {
    this.commonService.setCurrentPageTitle($localize `${this.title}`);
  }
}
