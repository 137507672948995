<div class="container">
  <div class="quote-box"><!-- picture-generator.component.html -->
      <h2>{{translateService.translate("Social Media Picture Generator")}}</h2>

      <!-- Quote Selection -->
      <label for="quoteSelect">{{translateService.translate("Select a Quote:")}}</label><br>
      <select id="quoteSelect" [(ngModel)]="selectedQuote" (change)="drawPicture()">
        <option *ngFor="let quote of quoteService.getQuotes()" [value]="quote.id">{{ (quote.quote.length>42)? (quote.quote | slice:0:42)+'...':(quote.quote) }} - {{quote.author}}</option>
      </select>
    <br><br>
    <!-- Font Selection -->
    <label for="fontSelect">{{translateService.translate("Font:")}}</label><br>
    <select id="fontSelect" [(ngModel)]="selectedFont" (change)="drawPicture()">
      <option *ngFor="let font of fonts" [value]="font">{{ font }}</option>
    </select>
      <br><br>
      <!-- Background Color Picker -->
      <label for="bgColorPicker">{{translateService.translate("Background Color:")}}</label><br>
      <input type="color" id="bgColorPicker" [(ngModel)]="backgroundColor" (change)="drawPicture()" />


      <!-- Picture Type Buttons -->
      <div class="btn-picture-type-group">
        <label>{{translateService.translate("Picture Type")}}</label>
        <button class="btn-primary" *ngFor="let type of pictureTypes" (click)="generatePicture(type)">{{ type.name }}</button>
      </div>

      <!-- Generated Picture -->
      <canvas #canvasElement *ngIf="generatedPicture" [attr.width]="generatedPicture.width" [attr.height]="generatedPicture.height" style="display: none;"></canvas>
      <img *ngIf="generatedPictureDataUrl" [src]="generatedPictureDataUrl" [style.width.px]="generatedPicture?.width" [style.height.px]="generatedPicture?.height" />

      <!-- Export Buttons -->
      <div class="btn-group" *ngIf="generatedPicture">
        <button class="btn-clear" (click)="exportImage('png')">Export as PNG</button>
        <button class="btn-clear" (click)="exportImage('jpg')">Export as JPG</button>
      </div>
    </div>
  </div>
