import {Component, Input, OnInit} from '@angular/core';
import {Project} from "../../models/project";
import {ProjectService} from "../../services/project.service";
import {Timesheet} from "../../models/timesheet";
import {TimesheetService} from "../../services/timesheet.service";
import {Meeting} from "../../models/meeting";
import {MeetingService} from "../../services/meeting.service";
import {CommonService} from "../../services/common.service";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit{
  projectList:Project[] = [];
  timesheets:Timesheet[] = [];
  meetings: Meeting[] = [];
  emptyProject:Project = {
    id:"-1",
    name:"-1",
    status : "-1",
    taskList:[],
    resources:[]
  };
  dummyProject:Project = {
    id:"-1",
    name:"dummy",
    status : "-1",
    taskList:[],
    resources:[]
  };

  constructor(public projectService: ProjectService,
              public timesheetService: TimesheetService,
              public commonService:CommonService,
              public meetingService: MeetingService) {
    this.projectList = projectService.getUserProjects();
    this.timesheets = this.timesheetService.userTimesheet;
    this.meetings = this.meetingService.userMeetings;
  }
  ngOnInit() {
    this.commonService.setCurrentPageTitle('Dashboard');
  }
}
