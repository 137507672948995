<div class="container">
  <span class="first-skill">
    <span class="decorator">/</span>
    {{ translateService.translate("skills-digitization") }}
  </span>
  <span class="skill">
    <span class="decorator">/</span>
    {{ translateService.translate("Audit") }}
  </span>
  <span class="skill">
    <span class="decorator">/</span>
    {{ translateService.translate("Code") }}
  </span>
  <span class="skill">
    <span class="decorator">/</span>
    {{ translateService.translate("UX & UI") }}
  </span>

</div>
