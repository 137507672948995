<div id="offers" class="container">
  <div>
    <span class="ask-subscription">
      <h2>{{ translateService.translate("want-reccuring-support") }}</h2>
      <button (click)="askPackage()">{{ translateService.translate("choose-a-package") }}</button>
    </span>
    <span class="ask-quote">
      <h2>{{ translateService.translate("or-a-specific-request") }}</h2>
      <button (click)="askQuote()">{{ translateService.translate("get-a-quote") }}</button>
    </span>
    <span class="contact">
      <h2>{{ translateService.translate("or-simply-get-in-touch") }}</h2>
      <button (click)="askContact()">{{ translateService.translate("contact-us") }}</button>
    </span>
  </div>
  <img src="assets/img/tranquilys-digital-consultant.jpg" alt=""/>
</div>
