import {AfterViewInit, Component} from '@angular/core';
import {ModalService} from "./services/modal.service";
import {AuthService} from "./services/auth.service";
import {ProjectService} from "./services/project.service";
import {CommonService} from "./services/common.service";
import {TimesheetService} from "./services/timesheet.service";
import {Meta, Title} from "@angular/platform-browser";
import {run} from "vanilla-cookieconsent";
import {TranslateService} from "./services/translate.service";
import {OfferingService} from "./services/offering.service";
import {PackageService} from "./services/package.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {
  constructor(private meta: Meta,
              private titleService: Title,
              public authService: AuthService,
              public modalService: ModalService,
              public offeringService: OfferingService,
              public packageService: PackageService,
              public projectService: ProjectService,
              public timesheetService: TimesheetService,
              public translateService: TranslateService,
              public commonService: CommonService) {
    this.translateService.loadLanguage(navigator.language);
    this.offeringService.initOffering();
    this.packageService.initPackages();
    this.titleService.setTitle(this.translateService.translate("Tranquilys. Surf the digital wave in serenity"));
    this.meta.addTag({name: 'title', content: this.title});
    this.meta.addTag({name: 'og:title', content: this.title});
    this.meta.addTag({name: 'og:site_name', content: this.title});
    this.meta.addTag({name: 'og:description', content: this.description});
    this.meta.addTag({name: 'og:url', content: 'https://tranquilys.com'});
    this.meta.addTag({name: 'og:image', content: 'https://tranquilys.com/assets/img/og-tranquilys-cover.jpg'});
    this.meta.addTag({name: 'twitter:title', content: this.title});
    this.meta.addTag({name: 'twitter:description', content: this.description});
    this.meta.addTag({name: 'twitter:image', content: 'https://tranquilys.com/assets/img/og-tranquilys-cover.jpg'});
  }

  title = 'Tranquilys. Surf the digital wave in serenity';
  description = $localize`Innovation experts, specialists in digital transformation, we create custom solutions to elevate your business and address your industry's unique needs.`;
  protected readonly ModalService = ModalService;


  ngAfterViewInit(): void {
    run({
      guiOptions: {
        consentModal: {
          layout: "cloud inline",
          position: "bottom left",
          equalWeightButtons: false,
          flipButtons: true
        },
        preferencesModal: {
          layout: "box",
          position: "left",
          equalWeightButtons: true,
          flipButtons: true
        }
      },
      categories: {
        necessary: {
          readOnly: true
        },
        analytics: {
          autoClear: {
            cookies: [
              {
                name: /^_ga/,   // regex: match all cookies starting with '_ga'
              },
              {
                name: '_gid',   // string: exact cookie name
              }
            ]
          },

          // https://cookieconsent.orestbida.com/reference/configuration-reference.html#category-services
          services: {
            ga: {
              label: 'Google Analytics',
              onAccept: () => {},
              onReject: () => {}
            },
            youtube: {
              label: 'Youtube Embed',
              onAccept: () => {},
              onReject: () => {}
            },
          }
        },
      },
      language: {
        default: "en",
        autoDetect: "browser",
        translations: {
          en: {
            consentModal: {
              title: "Hello surfer, it's cookie time!",
              description: "We use different types of cookies to offer you the best possible browsing experience. Technical cookies are essential for the proper functioning of our site, while analytics cookies help us continuously improve it.",
              acceptAllBtn: "Accept all",
              acceptNecessaryBtn: "Reject all",
              showPreferencesBtn: "Manage preferences",
              footer: "<a href=\"/privacy-policy\">Privacy Policy</a>\n<a href=\"/legal-notice\">Legal Notice</a>"
            },
            preferencesModal: {
              title: "Consent Preferences Center",
              acceptAllBtn: "Accept all",
              acceptNecessaryBtn: "Reject all",
              savePreferencesBtn: "Save preferences",
              closeIconLabel: "Close modal",
              serviceCounterLabel: "Service|Services",
              sections: [
                {
                  title: "Cookie Usage",
                  description: "This site uses the minimum number of cookies necessary for its operation and improvement."
                },
                {
                  title: "Strictly Necessary Cookies <span class=\"pm__badge\">Always Enabled</span>",
                  description: "These cookies are essential for the website to function properly and cannot be disabled.",
                  linkedCategory: "necessary"
                },
                {
                  title: "Analytics Cookies",
                  description: "Analytics cookies help us understand how visitors use our website so we can improve the user experience.",
                  linkedCategory: "analytics"
                },
                {
                  title: "More information",
                  description: "For any query in relation to the policy on cookies and your choices, please <a class=\"cc__link\" href=\"mailto:dpo@tranquilys.com\">contact us</a>."
                }
              ]
            }
          },
          fr: {
            consentModal: {
              title: "Bonjour surfer, c'est l'heure des cookies!",
              description: "Nous utilisons différents types de cookies pour vous offrir la meilleure expérience de navigation possible. Les cookies techniques sont essentiels au bon fonctionnement de notre site, tandis que les cookies d'analyse nous aident à l'améliorer continuellement.",
              acceptAllBtn: "Tout accepter",
              acceptNecessaryBtn: "Tout rejeter",
              showPreferencesBtn: "Gérer les préférences",
              footer: "<a href=\"/privacy-policy\">Politique de confidentialité</a>\n<a href=\"/legal-notice\">Mentions légales</a>"
            },
            preferencesModal: {
              title: "Préférences de cookies",
              acceptAllBtn: "Tout accepter",
              acceptNecessaryBtn: "Tout rejeter",
              savePreferencesBtn: "Sauvegarder les préférences",
              closeIconLabel: "Fermer la modale",
              serviceCounterLabel: "Services",
              sections: [
                {
                  title: "Utilisation des Cookies",
                  description: "Ce site utilise le minimum de cookie nécessaire à son fonctionnement et à son amélioration."
                },
                {
                  title: "Cookies Strictement Nécessaires <span class=\"pm__badge\">Toujours Activé</span>",
                  description: "Ces cookies sont essentiels au bon fonctionnement du site web et ne peuvent pas être désactivés.",
                  linkedCategory: "necessary"
                },
                {
                  title: "Cookies Analytiques",
                  description: "Les cookies d'analyse nous permettent de comprendre comment les visiteurs utilisent notre site web afin d'améliorer l'expérience utilisateur.",
                  linkedCategory: "analytics"
                },
                {
                  title: "Plus d'informations",
                  description: "Pour toute question relative à la politique sur les cookies et à vos choix, n'hésitez pas à <a class=\"cc__link\" href=\"mailto:dpo@tranquilys.com\">nous contacter</a>."
                }
              ]
            }
          }
        }
      }
    });
  }
}
