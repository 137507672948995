export const environment = {
  production: true,
  jwtserver : 'https://b-prod.com',
  jwtport:'',
  jwtLoginUrl:'/login.php', //  '/auth/login'
  jwtRegisterUrl:'/register.php', //  '/auth/login'
  atTokenId: 'patPrTwEHsFUaUvVG',
  atToken: 'patPrTwEHsFUaUvVG.f30e962112572fa4e76fc988194e17b1faa0cd33d4bd84969246d6058d909c54',
  apiDirectory : 'tranquilys',
  apiNotionDirectory : 'notion',
  gaEvt: {
    sign_up : {
      name : "sign_up",
      details: "",
      category: "custom"
    },
    ad_impression : {
      name : "ad_impression",
      details: "",
      category: "custom"
    },
    generate_lead : {
      name : "generate_lead",
      details: "",
      category: "custom"
    },
    login : {
      name : "login",
      details: "",
      category: "custom"
    },
    logout : {
      name : "logout",
      details: "",
      category: "custom"
    },
    purchase : {
      name : "purchase",
      details: "",
      category: "custom"
    },
    select_content : {
      name : "select_content",
      details: "",
      category: "custom"
    }
  }
};
