import { Component } from '@angular/core';
import {ModalService} from "../../../../services/modal.service";

@Component({
  selector: 'app-add-resource',
  templateUrl: './add-resource.component.html',
  styleUrls: ['./add-resource.component.scss']
})
export class AddResourceComponent {
  constructor(private modalService: ModalService) {
  }
  displayUploadModal() {
    this.modalService.showModal();
  }
}
