<div class="subscription-box">
  <span class="btn-grp">
      <button (click)="decreaseOffer()" class="decrease" [class.disabled] = "currentOffer == 0">
        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 32 32"><path fill="#ffffff" d="M16 2a14 14 0 1 0 14 14A14 14 0 0 0 16 2m8 15H11.85l5.58 5.573L16 24l-8-8l8-8l1.43 1.393L11.85 15H24Z"/><path fill="none" d="m16 8l1.43 1.393L11.85 15H24v2H11.85l5.58 5.573L16 24l-8-8z"/></svg>
      </button>
      <button>
        <span>{{ packageService.getPackages()[currentOffer].title }}</span>
      </button>
      <button (click)="increaseOffer()" class="increase" [class.disabled] = "currentOffer == packageService.getPackages().length - 1 ">
        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 32 32"><path fill="#ffffff" d="M2 16A14 14 0 1 0 16 2A14 14 0 0 0 2 16m6-1h12.15l-5.58-5.607L16 8l8 8l-8 8l-1.43-1.427L20.15 17H8Z"/><path fill="none" d="m16 8l-1.43 1.393L20.15 15H8v2h12.15l-5.58 5.573L16 24l8-8z"/></svg>
      </button>
  </span>
  <span class="subscription-box-content">
    <ul *ngIf="translateService.getShortLanguage() == 'fr'">
      <li><span class="hours">{{ packageService.getPackages()[currentOffer].codingHours }}h</span><span i18n>work</span>&nbsp;&nbsp;&middot;&nbsp;&nbsp;<span class="hours">{{ packageService.getPackages()[currentOffer].callHours }}h</span><span i18n>call</span></li>
      <li *ngFor="let serviceOffer of packageService.getPackages()[currentOffer].services_fr">
        {{serviceOffer}}
      </li>
    </ul>
    <ul *ngIf="translateService.getShortLanguage() !== 'fr'">
      <li><span class="hours">{{ packageService.getPackages()[currentOffer].codingHours }}h</span><span i18n>work</span>&nbsp;&nbsp;&middot;&nbsp;&nbsp;<span class="hours">{{ packageService.getPackages()[currentOffer].callHours }}h</span><span i18n>call</span></li>
      <li *ngFor="let serviceOffer of packageService.getPackages()[currentOffer].services_en">
        {{serviceOffer}}
      </li>
    </ul>
    <button (click)="showSubscription()">
      <span>{{ translateService.translate("subscribe") }}</span><br/>
      {{packageService.getPackages()[currentOffer].price}}<span class="cur-per-month">{{ translateService.translate("eur-per-month") }}</span>
    </button>
    <ul class="service-tag-list">
      <li *ngFor="let serviceTag of packageService.getPackages()[currentOffer].services">
        /{{serviceTag}}
      </li>
    </ul>
  </span>
</div>
