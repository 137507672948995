import { Injectable } from '@angular/core';
import {catchError, EMPTY, map, Subscription} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {Timesheet} from "../models/timesheet";
import {EnvironmentService} from "./environment.service";

@Injectable({
  providedIn: 'root'
})
export class TimesheetService {
  userTimesheet: Timesheet[] =[];
  // @ts-ignore
  getTimesheetsSubscription:Subscription;

  constructor(private environmentService: EnvironmentService, private http: HttpClient) {
  }

  notionJsonToTimesheet(json:any) {
    let tempTimesheet: Timesheet = {
      id: json.id,
      name: json["properties"]["Name"]["title"][0]["plain_text"],
      hours:json["properties"]["Hours"]["formula"]["number"],
      rate: json["properties"]["Hourly rate"]["number"], // hour rate
      status: this.getTimesheetStatus(json["properties"]["Status"]["select"]?json["properties"]["Status"]["select"]["name"]:"nonbillable"), // topay, canceled, nonbillable, paid
      creationDate: json["properties"]["Task Datetime"]["date"]?json["properties"]["Task Datetime"]["date"]["start"]?json["properties"]["Task Datetime"]["date"]["start"]:json["properties"]["Task Datetime"]["date"]:"no date",
      currency:'EUR',//EUR, USD, ...
      taskId:json["properties"]["Attached Task"]["relation"][0]?json["properties"]["Attached Task"]["relation"][0]["id"]:'',
      projectId: json["properties"]["Attached Project"]["relation"][0]?json["properties"]["Attached Project"]["relation"][0]["id"]:'',
      lastUpdate: json["last_edited_time"],
      closeDate:new Date(),
    }
    return tempTimesheet;
  }

  retrieveTimesheets(customerId:string) {
    this.getTimesheetsSubscription = this.getTimesheetFromNotion(customerId).subscribe();
  }
  getTimesheetFromNotion(customerId:string = "test") {
    const headers = { 'content-type': 'application/json', 'Authorization': 'Bearer secret_7zOrfx8Ut1YwwCt4u06dkDYB9c2LqucGQSU2f5fZK4G', 'Notion-Version': '2022-06-28'};

    const requestJSON ={
      "filter": {
        "property": "Customer",
        "relation": {
          "contains": customerId
        }
      }};

    const body=JSON.stringify(requestJSON);
    return this.http.post(this.environmentService.getNotionUrl('getNotionTimesheets'),body, {headers})
      .pipe(map(res => {
          // @ts-ignore
          for (let timesheet of res["results"]) {
            this.userTimesheet.push(this.notionJsonToTimesheet(timesheet));
          }
        }),
        catchError((err) => {
          console.log('Error retrieving Timesheets', err);
          return EMPTY;
        }));

  }

  getTimesheetStatus(timesheetStatus: string) {
    let tempTimesheetStatus:string;
    switch(timesheetStatus) {
      case 'To Pay' :
                tempTimesheetStatus = 'topay';
                break;
      case 'Non-Billable':
        tempTimesheetStatus = 'nonbillable';
        break;
      default : return timesheetStatus.toLowerCase();
    }
    return tempTimesheetStatus.toLowerCase();
  }



  getTimesheets(filter:string = 'all') {
    if (filter != 'all') {
      let tempTimesheets = [];
      for (let timesheet of this.userTimesheet) {
        if (timesheet.status == filter) tempTimesheets.push(timesheet)
      }
      return tempTimesheets;
    }
    return this.userTimesheet;
  }

  countTimesheetHours(filter = 'all', startDate? :Date, endDate ? :Date) {
    let hours: number = 0;

    switch (filter) {
      case 'To pay' :
      case 'topay' :
      case 'ongoing' :
        if (startDate && endDate) {
          for (let timesheet of this.userTimesheet) { if ((timesheet.status === 'topay'||timesheet.status === 'to pay') && new Date(timesheet.creationDate) > startDate && new Date(timesheet.creationDate) < endDate) hours += timesheet.hours ? timesheet.hours : 0}
        }
        else {
          for (let timesheet of this.userTimesheet) { if (timesheet.status === 'topay'||timesheet.status === 'to pay') hours += timesheet.hours ? timesheet.hours : 0}
        }
        break;
      case 'closed' :
        if (startDate && endDate) {
          for (let timesheet of this.userTimesheet) { if ((timesheet.status === 'paid'||timesheet.status === 'canceled'||timesheet.status === 'archived') && new Date(timesheet.creationDate) > startDate && new Date(timesheet.creationDate) < endDate) hours += timesheet.hours ? timesheet.hours : 0}
        }
        else {
          for (let timesheet of this.userTimesheet) { if (timesheet.status === 'paid'||timesheet.status === 'canceled'||timesheet.status === 'archived') hours += timesheet.hours ? timesheet.hours : 0}
        }
        break;
      case 'packaged' :
      case 'Packaged' :
        if (startDate && endDate) {
          for (let timesheet of this.userTimesheet) {

            if ((timesheet.status.toLowerCase() === 'packaged') && new Date(timesheet.creationDate) > startDate && new Date(timesheet.creationDate) < endDate) hours += timesheet.hours ? timesheet.hours : 0;
          }
        }
        else {
          for (let timesheet of this.userTimesheet) { if (timesheet.status.toLowerCase() === 'packaged') hours += timesheet.hours ? timesheet.hours : 0}
        }
        break;
      case 'all':
      default :
        if (startDate && endDate) {
          for (let timesheet of this.userTimesheet) { if (new Date(timesheet.creationDate) > startDate && new Date(timesheet.creationDate) < endDate)hours += timesheet.hours ? timesheet.hours : 0}
        }
        else {
          for (let timesheet of this.userTimesheet) { hours += timesheet.hours ? timesheet.hours : 0}
        }
        break;
    }
    return hours;
  }
  calculateTimesheetToPay() {
    let rate: number = 0;
    for (let timesheet of this.userTimesheet) { if (timesheet.status === 'topay'||timesheet.status === 'to pay') rate += timesheet.rate ? timesheet.hours * timesheet.rate : 0}
    return rate + "€";
  }


  emptyTimesheet() {
    this.userTimesheet = [];
    this.getTimesheetsSubscription.unsubscribe();
  }
}
