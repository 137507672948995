import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {catchError, EMPTY, map, Subscription} from "rxjs";
import {EnvironmentService} from "./environment.service";
import {Quote} from "../models/quote";

@Injectable({
  providedIn: 'root'
})
export class QuoteService {
  quotes: Quote[] =[];

  // @ts-ignore
  getQuotesSubscription:Subscription;

  constructor(private environmentService: EnvironmentService, private http: HttpClient) {
  }

  retrieveQuotes() {
    this.emptyQuotesSubscription();
    this.getQuotesSubscription = this.getQuotesFromNotion().subscribe();
  }
  emptyQuotesSubscription() {
    if (this.getQuotesSubscription !== undefined) this.getQuotesSubscription.unsubscribe();
  }
  notionJsonToQuote(json:any) {
    let tempQuote: Quote = {
      id : json.id,
      quote :json["properties"]["quote"]["title"][0]["plain_text"],
      author : json["properties"]["author"]["rich_text"][0]["plain_text"],
      tags : []
    }

    let tempTags = [];
    for (let tag of json["properties"]["tags"]["multi_select"]) {
      tempTags.push(tag["name"]);
    }
    tempQuote.tags = tempTags;

    return tempQuote;
  }
  getQuotes()  {
    return this.quotes;
  }
  getQuotesFromNotion() {
    const headers = { 'content-type': 'application/json', 'Authorization': 'Bearer secret_7zOrfx8Ut1YwwCt4u06dkDYB9c2LqucGQSU2f5fZK4G', 'Notion-Version': '2022-06-28'};

    const requestJSON = {
      "filter": {
        "and": [
          {
            "property": "published",
            "checkbox": {
              "equals": true
            }
          },
          {
            "property": "published",
            "checkbox": {
              "equals": true
            }
          }
        ]
      }
    };
    const body=JSON.stringify(requestJSON);

    return this.http.post(this.environmentService.getNotionUrl('getNotionQuotes'),body, {headers})
      .pipe(map(res => {
          // @ts-ignore
          for (let quoteItem of res["results"]) {
            this.quotes.push(this.notionJsonToQuote(quoteItem));
          }
        }),
        catchError((err) => {
          console.log('Error retrieving quotes', err);
          return EMPTY;
        }));
  }

  getQuoteById(quoteId: string): Quote | undefined {
    return this.quotes.find(quote => quote.id === quoteId);
  }
}
