import { NgModule } from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {PublicComponent} from "./public/public.component";
import {SecuredComponent} from "./secured/secured.component";
import {authGuard} from "./guards/auth.guard";
import {OffProjectComponent} from "./secured/project/off-project/off-project.component";
import {ProjectComponent} from "./secured/project/project.component";
import {DashboardComponent} from "./secured/dashboard/dashboard.component";
import {ProfileComponent} from "./secured/profile/profile.component";
import {SubscriptionComponent} from "./secured/subscription/subscription.component";
import {PrivacyComponent} from "./secured/privacy/privacy.component";
import {TechnicalReviewComponent} from "./technical-review/technical-review.component";
import {GetYourTranquilysComponent} from "./get-your-tranquilys/get-your-tranquilys.component";
import {DigitalMaturityComponent} from "./digital-maturity/digital-maturity.component";
import {PrivacyPolicyComponent} from "./privacy-policy/privacy-policy.component";
import {LegalNoticeComponent} from "./legal-notice/legal-notice.component";
import {PressKitComponent} from "./press-kit/press-kit.component";
import {SocialPictureGeneratorComponent} from "./secured/social-picture-generator/social-picture-generator.component";
import {CaseStudyComponent} from "./public/case-study/case-study.component";

const routes: Routes = [
  {
    path:'',
    pathMatch:'full',
    redirectTo:'public'
  },
  {
    path: 'public',
    component: PublicComponent },
  {
    path: 'technical-review',
    component: TechnicalReviewComponent },
  {
    path: 'case-study/:id',
    component: CaseStudyComponent },
  {
    path: 'get-your-tranquilys',
    component: GetYourTranquilysComponent },
  {
    path: 'digital-maturity',
    component: DigitalMaturityComponent },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent },
  {
    path: 'legal-notice',
    component: LegalNoticeComponent },
  {
    path: 'press-kit',
    component: PressKitComponent },
  {
    path: 'secured',
    component: SecuredComponent,
    /* TODO uncomment */
    canActivate: [authGuard]},
  {
    path: 'secured/dashboard',
    component: DashboardComponent,
    /* TODO uncomment */
    canActivate: [authGuard]},
  {
    path: 'secured/project',
    component: ProjectComponent,
    /* TODO uncomment */
    canActivate: [authGuard]},
  {
    path: 'secured/project/off',
    component: OffProjectComponent,
    /* TODO uncomment */
    canActivate: [authGuard]},
  {
    path: 'secured/profile',
    component: ProfileComponent,
    /* TODO uncomment */
    canActivate: [authGuard]},
  {
    path: 'secured/subscription',
    component: SubscriptionComponent,
    /* TODO uncomment */
    canActivate: [authGuard]},
  {
    path: 'secured/privacy',
    component: PrivacyComponent,
    /* TODO uncomment */
    canActivate: [authGuard]},
  {
    path: 'secured/picture-generator',
    component: SocialPictureGeneratorComponent,
    /* TODO uncomment */
    canActivate: [authGuard]},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'top', enableViewTransitions:true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
