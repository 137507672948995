import { Component } from '@angular/core';

@Component({
  selector: 'app-current-project-links',
  templateUrl: './current-project-links.component.html',
  styleUrls: ['./current-project-links.component.scss']
})
export class CurrentProjectLinksComponent {

}
